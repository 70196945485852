export default {
    IS_DEV: process.env.REACT_APP_IS_DEV === 'true',
    // API_ENDPOINT: "http://192.168.2.111:3100/dev/api/v1/cms",
    // API_ENDPOINT2: "http://192.168.2.111:3100/dev/api/v1",
    // API_ENDPOINT: "https://d2p9uudcljsrs0.cloudfront.net/dev/api/v1/cms",
    // API_ENDPOINT2: "https://d2p9uudcljsrs0.cloudfront.net/dev/api/v1",

    API_BASE_ENDPOINT: process.env.REACT_APP_API_BASE_ENDPOINT,
    API_ENDPOINT: `${process.env.REACT_APP_API_BASE_ENDPOINT}/cms`,

    DEFAULT_REDIRECT_URL: 'https://eshopuatcms.1010corporate.com/auth/admin-login-redirect',

    // API_ENDPOINT: "https://lm4wvzjj09.execute-api.ap-southeast-1.amazonaws.com/feature/api/v1/cms",
    // API_ENDPOINT2: "https://lm4wvzjj09.execute-api.ap-southeast-1.amazonaws.com/feature/api/v1",
    CLIENT_ID: "65sj5bnt54uk0naevbgalb83k9", //For HKT
    CLIENT_ID_FOR_ZENSIS: "74srk6phfsje51g2a54ia2cn9u", //For Zensis
    CLIENT_ID_FOR_INTERNAL_ZENSIS: "7pq0o1focsakrfj18ejbnoc2mf", //For Zensis
    DATE_FORMAT: "DD/MM/YYYY",
    DATE_TIME_FORMAT: "DD/MM/YYYY, HH:mm",
    INVALID_INPUT_ERR_MSG: "The information you entered is incorrect. Please check your information and try again.",
}