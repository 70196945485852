import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { styled as styledMui, createTheme, ThemeProvider } from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
    palette: {
        primary: {
            main: "#6D5500"
        },
        secondary: {
            main: "#AEAEAE"
        },
        neon: {
            main: "#F2A000",
        },
        darkYellow: {
            main: "#6D5500"
        },
        lightYellow: {
            main: "#BB9202"
        },
        info: {
            main: "#464646",
            light: "#AEAEAE",
            dark: "#000",
        },
    },
    typography: {
        fontFamily: ['Roboto'].join(','),
        main: {
            color: "#000",
        },
        secondary: {
            color: "#AEAEAE"
        },
        header1: {
            color: "#BB9202",
            fontWeight: 500,
            fontSize: 14
        },
        header: {
            color: "#6D5500",
            fontWeight: 600,
            fontSize: 14
        },
        header3: {
            color: "#F2A000",
            fontWeight: 500,
            fontSize: 14
        },
        ellipsis: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            flex: 1,
            fontWeight: 'normal'
        }
    }
});

const StyledTypography = styledMui(Typography)(
    ({ theme, color, header, bold, fontWeight, fontSize, breakSpaces }) => `
        font-size: ${fontSize || 16}px;
        ${color ? `color: ${color};` : ""}
        ${header ? `margin-bottom: 10px;` : ""}
        ${bold ? `font-weight: bold;` : ""}
        ${fontWeight ? `font-weight: ${fontWeight};` : ""}
        ${breakSpaces ? `white-space: break-spaces;` : ""}
  `,
);

const Text = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <StyledTypography component="p" {...props} />
        </ThemeProvider>
    )
}

export default Text

Text.propTypes = {
    children: PropTypes.any,
    color: PropTypes.string,
};