import { InputAdornment, Grid } from '@mui/material';
import _ from 'lodash';
import { Controller } from "react-hook-form";
import {
    FileStatus
} from '../../../components';
import {
    AutoCompleteWithList,
    Checkbox,
    DatePicker,
    Image,
    RadioGroup,
    Select,
    TextField,
    Typography
} from '../../../components/share';
import dayjs from 'dayjs';

const PlanInfoFields = ({ name, control, errors = {}, readOnly }) => [
    {
        id: "transaction-no",
        component: (
            <Controller
                name={`${name}.transactionNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Transaction No."
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "order-no",
        component: (
            <Controller
                name={`${name}.orderNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Order No."
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "planName",
        component: (
            <Controller
                name={`${name}.plan.name.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="planName"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            // render={({ field }) => (
            //     <Select
            //         title="Plan Name"
            //         direction="vertical"
            //         fullWidth
            //         textFieldProps={{
            //             placeholder: "Select..."
            //         }}
            //         options={[
            //             {
            //                 name: "Category 1"
            //             }
            //         ]}
            //         value={field.value}
            //         onChange={(e, value) => {
            //             field.onChange(value)
            //         }}
            //         disabled={readOnly}
            //     // error={errors?.company}
            //     // helperText={errors?.company?.message}
            //     />
            // )}
            />
        )
    }, { id: "spacing" },
    {
        id: "monthly-fee",
        component: (
            <Controller
                name={`${name}.originalPrice`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Monthly Fee"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "discount",
        component: (
            <Controller
                name={`${name}.discountedPrice`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Discount"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "new-mobile-no",
        component: (
            <Controller
                name={`${name}.plan.newNumbers`}
                control={control}
                render={({ field }) => {
                    const v = field?.value?.map((item) => item.number)
                    return (
                        <TextField
                            {...field}
                            title="New Mobile No."
                            direction="vertical"
                            fullWidth
                            readOnly={readOnly}
                            value={v?.join(",")}
                        // error={error}
                        // helperText={error?.message}
                        />
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "choose-your-new-mobile-no",
        component: (
            <Controller
                name={`${name}.newMobile`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Choose Your New Mobile No."
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "effective-date",
        component: (
            <Controller
                name={`${name}.plan.efectiveDateForDisplay`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Effective Date"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            // render={({ field }) => (
            //     <DatePicker
            //         {...field}
            //         title="Effective Date"
            //         direction="vertical"
            //         fullWidth
            //         disabled={readOnly}
            //     // error={errors.startDate}
            //     // helperText={errors.startDate?.message}
            //     />
            // )}
            />
        )
    }, { id: "spacing" },
    {
        id: "automatic-renewal",
        component: (
            <Controller
                name={`${name}.plan.isAutoRenewal`}
                control={control}
                render={({ field }) => {
                    return (
                        <Checkbox
                            label="Automatic Renewal"
                            align='flex-start'
                            checked={field.value}
                            onChange={field.onChange}
                            typographyProps={{ variant: "header" }}
                            disabled={readOnly}
                        />
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "port-in-mobile-no",
        component: (
            <Controller
                name={`${name}.portInMobileNo`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Port In Mobile No."
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "status",
        component: (
            <Controller
                name={`${name}.status`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Status"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            // render={({ field }) => (
            //     <Select
            //         title="Status"
            //         direction="vertical"
            //         fullWidth
            //         textFieldProps={{
            //             placeholder: "Select..."
            //         }}
            //         options={[
            //             {
            //                 name: "Status 1"
            //             }
            //         ]}
            //         value={field.value}
            //         onChange={(e, value) => {
            //             field.onChange(value)
            //         }}
            //         disabled={readOnly}
            //     // error={errors?.company}
            //     // helperText={errors?.company?.message}
            //     />
            // )}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: 'free-vas-service',
        component: (
            <Controller
                name={`${name}.freeVas`}
                control={control}
                render={({ field }) => (
                    <AutoCompleteWithList
                        title="Free-VAS Service"
                        direction="vertical"
                        fullWidth
                        api="/vas"
                        getOptionLabel={(option) => `${option?.name?.en || ""} ${option?.name?.zh || ""}`}
                        readOnly={readOnly}
                        disabled={readOnly}
                        {...field}
                    // error={errors?.tnc}
                    // helperText={errors?.tnc?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: 'Paid-vas-service',
        component: (
            <Controller
                name={`${name}.paidVas`}
                control={control}
                render={({ field }) => (
                    <AutoCompleteWithList
                        title="Paid-VAS Service"
                        direction="vertical"
                        fullWidth
                        api="/vas"
                        getOptionLabel={(option) => `${option?.name?.en || ""} ${option?.name?.zh || ""}`}
                        readOnly={readOnly}
                        disabled={readOnly}
                        key={`paid-vas-${field?.value?._id}`}
                        {...field}
                    // error={errors?.tnc}
                    // helperText={errors?.tnc?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Terms & Conditions</Typography>
        )
    }, { id: "spacing" },
    {
        id: "detail-tncs",
        component: (
            <Controller
                name={`${name}.detailTncs`}
                control={control}
                render={({ field }) => {
                    if (!field.value || !field.value.length) return <div style={{ padding: -16 }} />
                    return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Detail Page T&C</Typography>
                            {
                                _.map(field?.value, (item) => {
                                    return (
                                        <RadioGroup
                                            title={item?.title?.en}
                                            options={[
                                                {
                                                    label: 'Agree',
                                                    value: 'agree'
                                                },
                                                {
                                                    label: 'Disagree',
                                                    value: 'disagree'
                                                },
                                            ]}
                                            row
                                            direction="vertical"
                                            disabled={readOnly}
                                            {...field}
                                            value={item.accepted ? "agree" : "disagree"}
                                        // error={errors?.freeRemovalService}
                                        // helperText={errors?.freeRemovalService?.message}
                                        />
                                    )
                                })
                            }
                        </>
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "detail-tncs",
        component: (
            <Controller
                name={`${name}.overallTncs`}
                control={control}
                render={({ field }) => {
                    if (!field.value || !field.value.length) return null
                    return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Overall T&C Agreement</Typography>
                            {
                                _.map(field?.value, (item) => {
                                    return (
                                        <RadioGroup
                                            title={item?.title?.en}
                                            options={[
                                                {
                                                    label: 'Agree',
                                                    value: 'agree'
                                                },
                                                {
                                                    label: 'Disagree',
                                                    value: 'disagree'
                                                },
                                            ]}
                                            row
                                            direction="vertical"
                                            disabled={readOnly}
                                            {...field}
                                            value={item.accepted ? "agree" : "disagree"}
                                        // error={errors?.freeRemovalService}
                                        // helperText={errors?.freeRemovalService?.message}
                                        />
                                    )
                                })
                            }
                        </>
                    )
                }}
            />
        )
    }, { id: "spacing" },
];

const ClientInfoFields = ({ name, control, errors = {}, readOnly }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Client Information</Typography>
        )
    }, { id: "spacing" },
    {
        id: "title",
        component: (
            <Controller
                name={`${name}.title`}
                control={control}
                render={({ field }) => (
                    <RadioGroup
                        title="Title"
                        iconType="square"
                        options={[
                            {
                                label: 'Mr',
                                value: 'mr'
                            },
                            {
                                label: 'Mrs',
                                value: 'mrs'
                            },
                            {
                                label: 'Miss',
                                value: 'miss'
                            },
                        ]}
                        row
                        direction="vertical"
                        disabled={readOnly}
                        {...field}
                    // error={errors?.freeRemovalService}
                    // helperText={errors?.freeRemovalService?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "full-name-en",
        component: (
            <Controller
                name={`${name}.fullName.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Full Name (English)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "full-name-zh",
        component: (
            <Controller
                name={`${name}.fullName.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Full Name (Chinese)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors.promotion?.zh}
                    // helperText={errors.promotion?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "doc-type",
        component: (
            <Controller
                name={`${name}.idType`}
                control={control}
                render={({ field }) => (
                    <RadioGroup
                        iconType="square"
                        options={[
                            {
                                label: 'HKID',
                                value: 'hkid'
                            },
                            {
                                label: 'Passport',
                                value: 'passport'
                            },
                        ]}
                        row
                        direction="vertical"
                        disabled={readOnly}
                        {...field}
                    // error={errors?.freeRemovalService}
                    // helperText={errors?.freeRemovalService?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "id-doc-upload",
        component: (
            <Controller
                name={`${name}.docType`}
                control={control}
                render={({ field }) => {
                    if (!field.value) {
                        return <TextField
                            title="ID Document Upload*"
                            direction="vertical"
                            fullWidth
                            readOnly={readOnly}
                            value={"N/A"}
                        />
                    }
                    return (
                        <FileStatus
                            title="ID Document Upload*"
                        />
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "certificate-number",
        component: (
            <Controller
                name={`${name}.idNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Certificate Number"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "company-name",
        component: (
            <Controller
                name={`${name}.companyName`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Company Name"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "job-position",
        component: (
            <Controller
                name={`${name}.companyPosition`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Job Position"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "billing-email",
        component: (
            <Controller
                name={`${name}.email`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Billing Email"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "phone-no",
        component: (
            <Controller
                name={`${name}.mobileNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Phone No."
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Malling Address</Typography>
        )
    }, { id: "spacing" },
    {
        id: "address1",
        component: (
            <Controller
                name={`${name}.address1`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Address Line 1"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "address2",
        component: (
            <Controller
                name={`${name}.address2`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Address Line 2"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "district",
        component: (
            <Controller
                name={`${name}.mailingDistrict`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="District"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "address-proof",
        component: (
            <Controller
                name={`${name}.addressProof`}
                control={control}
                render={({ field }) => (
                    <Image
                        title="Address Proof"
                        url={field?.value}
                        height={120}
                        width={120}
                        onChange={field.onChange}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Shipping Contact Profile</Typography>
        )
    }, { id: "spacing" },
    {
        id: "shipping-contract-title",
        component: (
            <Controller
                name={`${name}.contactUserInfo.title`}
                control={control}
                render={({ field }) => (
                    <RadioGroup
                        title='Title'
                        options={[
                            {
                                label: 'Mr',
                                value: 'mr'
                            },
                            {
                                label: 'Mrs',
                                value: 'mrs'
                            },
                            {
                                label: 'Miss',
                                value: 'miss'
                            },
                        ]}
                        row
                        direction="vertical"
                        disabled={readOnly}
                        {...field}
                    // error={errors?.freeRemovalService}
                    // helperText={errors?.freeRemovalService?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "shipping-contract-full-name-en",
        component: (
            <Controller
                name={`${name}.contactUserInfo.fullName.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Full Name (English)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "shipping-contract-full-name-zh",
        component: (
            <Controller
                name={`${name}.contactUserInfo.fullName.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Full Name (Chinese)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors.promotion?.zh}
                    // helperText={errors.promotion?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "shopping-contract-email",
        component: (
            <Controller
                name={`${name}.contactUserInfo.email`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Email"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "shopping-contract-contact",
        component: (
            <Controller
                name={`${name}.contactUserInfo.mobileNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Contact"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "shopping-contact-certificate-type",
        component: (
            <Controller
                name={`${name}.contactUserInfo.certificateType`}
                control={control}
                render={({ field }) => (
                    <RadioGroup
                        title="Certificate Type"
                        options={[
                            {
                                label: 'HKID',
                                value: 'hkid'
                            },
                            {
                                label: 'Passport',
                                value: 'passport'
                            },
                        ]}
                        row
                        direction="vertical"
                        disabled={readOnly}
                        {...field}
                    // error={errors?.freeRemovalService}
                    // helperText={errors?.freeRemovalService?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "shopping-contact-certificate-no",
        component: (
            <Controller
                name={`${name}.contactUserInfo.certificateNo`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Cerificate No."
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "shopping-contact-id-doc-upload",
        component: (
            <Controller
                name={`${name}.contactUserInfo.docType`}
                control={control}
                render={({ field }) => (
                    <Image
                        title="ID Document Upload"
                        url={field?.value}
                        height={120}
                        width={120}
                        onChange={field.onChange}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: 'hr'
    },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Self Pick Up</Typography>
        )
    }, { id: "spacing" },
    {
        id: "delivery-sell-pick-up",
        component: (
            <Controller
                name={`${name}.deliveryInfo.pickup`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Email"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Shop Delivery</Typography>
        )
    }, { id: "spacing" },
    {
        id: "delivery-address",
        component: (
            <Controller
                name={`${name}.deliveryInfo.deliveryAddress`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Address(English)"
                        direction="vertical"
                        fullWidth
                        multiline
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
];

const ExtraInfoFields = ({ name, control, errors = {}, readOnly }) => [
    {
        id: "ocid-no",
        component: (
            <Controller
                name={`${name}.ocid`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="OCID No."
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "web-seecurity-lite-id",
        component: (
            <Controller
                name={`${name}.webSecurityLiteId`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Web Security Lite ID"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "ssid",
        component: (
            <Controller
                name={`${name}.ssid`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="SSID"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "salesman-code",
        component: (
            <Controller
                name={`${name}.salesmanCode`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Salesman Code"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
]

const ProductInfoFields = ({ name, control, errors = {}, readOnly }) => [
    {
        id: "order-no",
        component: (
            <Controller
                name={`${name}.orderNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Order No."
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "product-name",
        component: (
            <Controller
                name={`${name}.product.name.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Product Name"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            // render={({ field }) => (
            //     <Select
            //         title="Product Name"
            //         direction="vertical"
            //         fullWidth
            //         textFieldProps={{
            //             placeholder: "Select..."
            //         }}
            //         options={[
            //             {
            //                 name: "12-6pm"
            //             }
            //         ]}
            //         value={field.value}
            //         onChange={(e, value) => {
            //             field.onChange(value)
            //         }}
            //         disabled={readOnly}
            //     // error={errors?.company}
            //     // helperText={errors?.company?.message}
            //     />
            // )}
            />
        )
    }, { id: "spacing" },
    {
        id: "product-sku",
        component: (
            <Controller
                name={`${name}.product.productSku`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Product Sku"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "product-internal-code",
        component: (
            <Controller
                name={`${name}.product.internalCode`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Product Internal Code"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "original-price",
        component: (
            <Controller
                name={`${name}.originalPrice`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Original Price"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "discounted-price",
        component: (
            <Controller
                name={`${name}.discountedPrice`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Discounted Price"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Sub-total</Typography>
        )
    }, { id: "spacing" },
    {
        id: "amountTotal",
        component: (
            <Controller
                name={`${name}.amountTotal`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Item Amount Charged"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "quantity",
        component: (
            <Controller
                name={`${name}.quantity`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Quantity"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Variant Options</Typography>
        )
    }, { id: "spacing" },
    {
        id: "order-no",
        component: (
            <Controller
                name={`${name}.product.productOptions`}
                control={control}
                render={({ field }) => {
                    if (field?.value && field?.value?.length > 0) return (
                        <>
                            {_.map(field.value, (item, index) => (
                                <TextField
                                    {...item}
                                    title={item?.optionName || `Variant ${index + 1}`}
                                    direction="vertical"
                                    fullWidth
                                    readOnly={readOnly}
                                    value={item?.valueName || "N/A"}
                                    style={{ marginBottom: 10 }}
                                // error={error}
                                // helperText={error?.message}
                                />
                            ))}
                        </>
                    )
                    else return <Typography variant="secondary">N/A</Typography>
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Free Gift</Typography>
        )
    }, { id: "spacing" },
    {
        id: "free-gifts",
        component: (
            <Controller
                name={`${name}.freeVas`}
                control={control}
                render={({ field }) => {
                    if (!field?.value?.length) {
                        return <Typography variant="secondary">N/A</Typography>
                    }
                    return (
                        <>
                            {
                                _.map(field.value, (item) => {
                                    return (
                                        <Grid container direction="column" style={{ marginBottom: 10 }} gap={2}>
                                            <Grid item>
                                                <TextField
                                                    title="Product Name"
                                                    direction="vertical"
                                                    fullWidth
                                                    readOnly={readOnly}
                                                    value={item?.name?.en}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    title="Original Price"
                                                    direction="vertical"
                                                    fullWidth
                                                    readOnly={readOnly}
                                                    value={item?.originalPrice}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    title="Discounted Price"
                                                    direction="vertical"
                                                    fullWidth
                                                    readOnly={readOnly}
                                                    value={item?.discountedPrice}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </>
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Paid-VAS Service</Typography>
        )
    }, { id: "spacing" },
    {
        id: 'Paid-vas-service',
        component: (
            <Controller
                name={`${name}.paidVas`}
                control={control}
                render={({ field }) => {
                    if (!field?.value?.length) {
                        return <Typography variant="secondary">N/A</Typography>
                    }
                    return (
                        <>
                            {
                                _.map(field.value, (item) => {
                                    return (
                                        <Grid container direction="column" style={{ marginBottom: 10 }} gap={2}>
                                            <Grid item>
                                                <TextField
                                                    title="Vas Name"
                                                    direction="vertical"
                                                    fullWidth
                                                    readOnly={readOnly}
                                                    value={item?.name?.en}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    title="Original Price"
                                                    direction="vertical"
                                                    fullWidth
                                                    readOnly={readOnly}
                                                    value={item?.originalPrice}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    title="Discounted Price"
                                                    direction="vertical"
                                                    fullWidth
                                                    readOnly={readOnly}
                                                    value={item?.discountedPrice}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </>
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Promotion Code</Typography>
        )
    }, { id: "spacing" },
    {
        // TODO
        id: 'Promo-code',
        component: (
            <Controller
                name={`${name}.promoCode`}
                control={control}
                render={({ field }) => {
                    if (!field?.value?.code) {
                        return <Typography variant="secondary">N/A</Typography>
                    }
                    return (
                        <Grid container direction="column" style={{ marginBottom: 10 }} gap={2}>
                            <Grid item>
                                <TextField
                                    title="CMS Display Title"
                                    direction="vertical"
                                    fullWidth
                                    readOnly={readOnly}
                                    value={field?.value?.name?.en}
                                    onChange={(e) => {
                                        field.onChange(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    title="Promo Code"
                                    direction="vertical"
                                    fullWidth
                                    readOnly={readOnly}
                                    value={field?.value?.code}
                                    onChange={(e) => {
                                        field.onChange(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    title="Discount Amount"
                                    direction="vertical"
                                    fullWidth
                                    readOnly={readOnly}
                                    value={field?.value?.discount}
                                    onChange={(e) => {
                                        field.onChange(e.target.value)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: "removal-of-regulated-electrical-appliances",
        component: (
            <Controller
                name={`${name}.needRemovalService`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Removal of Regulated Electrical Appliances"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        value={field.value === null || field.value === undefined ? "N/A" : field.value ? 'Y' : 'N'}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "hr"
    },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Total of Transaction</Typography>
        )
    }, { id: "spacing" },
    {
        id: "order-amount-total-parent",
        component: (
            <Controller
                name={`${name}.orderAmountTotal`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="ORDER TOTAL AMOUNT CHARGED"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },

    {
        id: "hr"
    },
    {
        id: 'header',
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Terms & Conditions</Typography>
        )
    }, { id: "spacing" },
    {
        id: "savePersonalInformation-tncs",
        component: (
            <Controller
                name={`${name}.savePersonalInformation`}
                control={control}
                render={({ field }) => {
                    if (!field.value || !field.value.length) return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Save Personal Info for next purchase</Typography>
                            <Typography variant="secondary">N/A</Typography>
                        </>
                    )
                    return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Save Personal Info for next purchase</Typography>
                            {
                                _.map(field?.value, (item) => {
                                    return (
                                        <RadioGroup
                                            title={item?.title?.en}
                                            options={[
                                                {
                                                    label: 'Agree',
                                                    value: 'agree'
                                                },
                                                {
                                                    label: 'Disagree',
                                                    value: 'disagree'
                                                },
                                            ]}
                                            row
                                            direction="vertical"
                                            disabled={readOnly}
                                            {...field}
                                            value={item.accepted ? "agree" : "disagree"}
                                        // error={errors?.freeRemovalService}
                                        // helperText={errors?.freeRemovalService?.message}
                                        />
                                    )
                                })
                            }
                        </>
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "directSaleThisServices-tncs",
        component: (
            <Controller
                name={`${name}.directSaleThisServices`}
                control={control}
                render={({ field }) => {
                    if (!field.value || !field.value.length) return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Direct Sale This Service</Typography>
                            <Typography variant="secondary">N/A</Typography>
                        </>
                    )
                    return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Direct Sale This Service</Typography>
                            {
                                _.map(field?.value, (item) => {
                                    return (
                                        <RadioGroup
                                            title={item?.title?.en}
                                            options={[
                                                {
                                                    label: 'Agree',
                                                    value: 'agree'
                                                },
                                                {
                                                    label: 'Disagree',
                                                    value: 'disagree'
                                                },
                                            ]}
                                            row
                                            direction="vertical"
                                            disabled={readOnly}
                                            {...field}
                                            value={item.accepted ? "agree" : "disagree"}
                                        // error={errors?.freeRemovalService}
                                        // helperText={errors?.freeRemovalService?.message}
                                        />
                                    )
                                })
                            }
                        </>
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "directSaleMyClubHKT-tncs",
        component: (
            <Controller
                name={`${name}.directSaleMyClubHKT`}
                control={control}
                render={({ field }) => {
                    if (!field.value || !field.value.length) return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Direct Sale My club HKT</Typography>
                            <Typography variant="secondary">N/A</Typography>
                        </>
                    )
                    return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Direct Sale My club HKT</Typography>
                            {
                                _.map(field?.value, (item) => {
                                    return (
                                        <RadioGroup
                                            title={item?.title?.en}
                                            options={[
                                                {
                                                    label: 'Agree',
                                                    value: 'agree'
                                                },
                                                {
                                                    label: 'Disagree',
                                                    value: 'disagree'
                                                },
                                            ]}
                                            row
                                            direction="vertical"
                                            disabled={readOnly}
                                            {...field}
                                            value={item.accepted ? "agree" : "disagree"}
                                        // error={errors?.freeRemovalService}
                                        // helperText={errors?.freeRemovalService?.message}
                                        />
                                    )
                                })
                            }
                        </>
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "productTncs-tncs",
        component: (
            <Controller
                name={`${name}.productTncs`}
                control={control}
                render={({ field }) => {
                    if (!field.value || !field.value.length) return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Product T&C</Typography>
                            <Typography variant="secondary">N/A</Typography>
                        </>
                    )
                    return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Product T&C</Typography>
                            {
                                _.map(field?.value, (item) => {
                                    return (
                                        <RadioGroup
                                            title={item?.title?.en}
                                            options={[
                                                {
                                                    label: 'Agree',
                                                    value: 'agree'
                                                },
                                                {
                                                    label: 'Disagree',
                                                    value: 'disagree'
                                                },
                                            ]}
                                            row
                                            direction="vertical"
                                            disabled={readOnly}
                                            {...field}
                                            value={item.accepted ? "agree" : "disagree"}
                                        // error={errors?.freeRemovalService}
                                        // helperText={errors?.freeRemovalService?.message}
                                        />
                                    )
                                })
                            }
                        </>
                    )
                }}
            />
        )
    }, { id: "spacing" },
    {
        id: "overallTncs-tncs",
        component: (
            <Controller
                name={`${name}.overallTncs`}
                control={control}
                render={({ field }) => {
                    if (!field.value || !field.value.length) return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Overall T&C Agreement</Typography>
                            <Typography variant="secondary">N/A</Typography>
                        </>
                    )
                    return (
                        <>
                            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Overall T&C Agreement</Typography>
                            {
                                _.map(field?.value, (item) => {
                                    return (
                                        <RadioGroup
                                            title={item?.title?.en}
                                            options={[
                                                {
                                                    label: 'Agree',
                                                    value: 'agree'
                                                },
                                                {
                                                    label: 'Disagree',
                                                    value: 'disagree'
                                                },
                                            ]}
                                            row
                                            direction="vertical"
                                            disabled={readOnly}
                                            {...field}
                                            value={item.accepted ? "agree" : "disagree"}
                                        // error={errors?.freeRemovalService}
                                        // helperText={errors?.freeRemovalService?.message}
                                        />
                                    )
                                })
                            }
                        </>
                    )
                }}
            />
        )
    }, { id: "spacing" },
]

const PaymentFields = ({ name, control, errors = {}, readOnly }) => [
    {
        id: "transaction-create-date",
        component: (
            <Controller
                name={`${name}.transactionCreateDate`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Transaction Create Date"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "transaction-update-date",
        component: (
            <Controller
                name={`${name}.transactionUpdateTime`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Transaction Update Date"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "transaction-capture-date",
        component: (
            <Controller
                name={`${name}.transactionCaptureTime`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Transaction Capture Date"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "ckes-token",
        component: (
            <Controller
                name={`${name}.ceksToken`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Credit Card CEKS Token"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "credit-card-approval-code",
        component: (
            <Controller
                name={`${name}.approvalCode`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Credit Card Approval Code"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "credit-card-type",
        component: (
            <Controller
                name={`${name}.cardType`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Card Type"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "credit-card-expiry-date",
        component: (
            <Controller
                name={`${name}.expiryDate`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Credit Card Expiry Date"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "credit-card-holder-name",
        component: (
            <Controller
                name={`${name}.holderName`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Credit Card Holder Name"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "opp-Transaction-Number",
        component: (
            <Controller
                name={`${name}.oppTransactionNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="OPP Transaction Number"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "opp-Transaction-Status",
        component: (
            <Controller
                name={`${name}.oppTransactionStatus`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="OPP Transaction Status"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "opp-Transaction-Response-Code",
        component: (
            <Controller
                name={`${name}.oppTransactionResponseCode`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="OPP Transaction Response Code"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "opp-Transaction-Payment-Result",
        component: (
            <Controller
                name={`${name}.oppTransactionPaymentResult`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="OPP Transaction Payment Result"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "opp-Transaction-Approval-Status",
        component: (
            <Controller
                name={`${name}.oppTransactionApprovalStatus`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="OPP Transaction Approval Status"
                        direction="vertical"
                        fullWidth
                        readOnly
                    />
                )}
            />
        )
    }, { id: "spacing" },
]

const DeliveryMethodFields = ({ name, control, errors = {}, readOnly, canViewPII }) => [
    // {
    //     id: 'header',
    //     component: (
    //         <Typography variant="header" sx={{ marginBottom: 1.5 }}>Self Pick Up</Typography>
    //     )
    // }, { id: "spacing" },
    {
        id: "delivery-method",
        component: (
            <Controller
                name={`${name}.deliveryInfo.deliveryMethod`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Delivery Method"
                        direction="vertical"
                        fullWidth
                        value={field?.value === 'delivery' ? "Delivery" : field?.value === "pickUp" ? "Self Pick Up" : "N/A"}
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "delivery-date",
        component: (
            <Controller
                name={`${name}.deliveryInfo.deliveryDate`}
                control={control}
                // render={({ field }) => (
                //     <TextField
                //         {...field}
                //         title="Delivery Date"
                //         direction="vertical"
                //         fullWidth
                //         readOnly={readOnly}
                //     // error={error}
                //     // helperText={error?.message}
                //     />
                // )}
                render={({ field }) => (
                    <DatePicker
                        {...field}
                        value={field?.value ? dayjs(field.value, "DD/MM/YYYY") : null}
                        title="Delivery Date"
                        type='date'
                        direction="vertical"
                        fullWidth
                        disabled={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "delivery-session",
        component: (
            <Controller
                name={`${name}.deliveryInfo.deliveryTime`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Delivery Session"
                        direction="vertical"
                        fullWidth
                        value={field.value?.toUpperCase()}
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "courier-charge",
        component: (
            <Controller
                name={`${name}.deliveryInfo.deliveryFee`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Courier Charge"
                        direction="vertical"
                        fullWidth
                        value={field.value}
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "delivery-address",
        component: (
            <Controller
                name={`${name}.deliveryInfo.deliveryAddress`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Address(English)"
                        direction="vertical"
                        fullWidth
                        multiline
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "mobile-no-for-cargo-verification-code",
        component: (
            <Controller
                name={`${name}.deliveryInfo.mobileNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Mobile no. (Can be used to receive cargo verification code)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "delivery-sell-pick-up",
        component: (
            <Controller
                name={`${name}.contactUserInfo.email`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Billing Email"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: 'hr'
    },
    {
        id: canViewPII ? 'header' : null,
        component: (
            <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Mailling Address</Typography>
        )
    }, { id: canViewPII ? "spacing" : null },
    {
        id: canViewPII ? "address1" : null,
        component: (
            <Controller
                name={`${name}.maillingAddress.address1`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Address Line 1"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: canViewPII ? "spacing" : null },
    {
        id: canViewPII ? "address2" : null,
        component: (
            <Controller
                name={`${name}.maillingAddress.address2`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Address Line 2"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: canViewPII ? "spacing" : null },
    {
        id: canViewPII ? "district" : null,
        component: (
            <Controller
                name={`${name}.maillingAddress.mailingDistrict`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="District"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: canViewPII ? "spacing" : null },
    {
        id: "hr"
    },
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Shipping Contact Profile</Typography>
        )
    }, { id: "spacing" },
    {
        id: "shipping-contract-title",
        component: (
            <Controller
                name={`${name}.contactUserInfo.title`}
                control={control}
                render={({ field }) => (
                    <RadioGroup
                        title='Title'
                        iconType="square"
                        options={[
                            {
                                label: 'Mr',
                                value: 'mr'
                            },
                            {
                                label: 'Mrs',
                                value: 'mrs'
                            },
                            {
                                label: 'Miss',
                                value: 'miss'
                            },
                        ]}
                        row
                        direction="vertical"
                        disabled={readOnly}
                        {...field}
                    // error={errors?.freeRemovalService}
                    // helperText={errors?.freeRemovalService?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "shopping-contract-full-name-en",
        component: (
            <Controller
                name={`${name}.contactUserInfo.fullName.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Full Name (English)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "shopping-contract-full-name-zh",
        component: (
            <Controller
                name={`${name}.contactUserInfo.fullName.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Full Name (Chinese)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors.promotion?.zh}
                    // helperText={errors.promotion?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "shopping-contract-email",
        component: (
            <Controller
                name={`${name}.contactUserInfo.email`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Email"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: canViewPII ? "shopping-contract-contact" : null,
        component: (
            <Controller
                name={`${name}.contactUserInfo.mobileNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Contact"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: canViewPII ? "spacing" : null },

]

const CustomerInfoFields = ({ name, control, errors = {}, readOnly, canViewPII }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Customer Information</Typography>
        )
    }, { id: "spacing" },
    {
        id: "login-email",
        component: (
            <Controller
                name={`${name}.loginEmail`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Account Login Email"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "title",
        component: (
            <Controller
                name={`${name}.title`}
                control={control}
                render={({ field }) => (
                    <RadioGroup
                        title="Title"
                        iconType="square"
                        options={[
                            {
                                label: 'Mr',
                                value: 'mr'
                            },
                            {
                                label: 'Mrs',
                                value: 'mrs'
                            },
                            {
                                label: 'Miss',
                                value: 'miss'
                            },
                        ]}
                        row
                        direction="vertical"
                        disabled={readOnly}
                        {...field}
                    // error={errors?.freeRemovalService}
                    // helperText={errors?.freeRemovalService?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "full-name-en",
        component: (
            <Controller
                name={`${name}.fullName.en`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Full Name (English)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    },
    {
        id: "full-name-zh",
        component: (
            <Controller
                name={`${name}.fullName.zh`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Full Name (Chinese)"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors.promotion?.zh}
                    // helperText={errors.promotion?.zh?.message}
                    />
                )}
            />
        )
    },
    {
        id: "doc-type",
        component: (
            <Controller
                name={`${name}.idType`}
                control={control}
                render={({ field }) => (
                    <RadioGroup
                        iconType="square"
                        options={[
                            {
                                label: 'HKID',
                                value: 'hkid'
                            },
                            {
                                label: 'Passport',
                                value: 'passport'
                            },
                        ]}
                        row
                        direction="vertical"
                        disabled={readOnly}
                        {...field}
                    // error={errors?.freeRemovalService}
                    // helperText={errors?.freeRemovalService?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: canViewPII ? "certificate-number" : null,
        component: (
            <Controller
                name={`${name}.idNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Certificate Number"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: canViewPII ? "spacing" : null },
    {
        id: "company-name",
        component: (
            <Controller
                name={`${name}.companyName`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Company Name"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "job-position",
        component: (
            <Controller
                name={`${name}.companyPosition`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Job Position"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "billing-email",
        component: (
            <Controller
                name={`${name}.email`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Billing Email"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: canViewPII ? "phone-no" : null,
        component: (
            <Controller
                name={`${name}.mobileNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Phone No."
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={errors?.promotion?.en}
                    // helperText={errors?.promotion?.en?.message}
                    />
                )}
            />
        )
    }, { id: canViewPII ? "spacing" : null },
];


const ExtraInfoFieldsForProduct = ({ name, control, errors = {}, readOnly }) => [
    {
        id: "ocid-no",
        component: (
            <Controller
                name={`${name}.oasisNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="OASIS No."
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "web-seecurity-lite-id",
        component: (
            <Controller
                name={`${name}.webSecurityLiteId`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Web Security Lite ID"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "ssid",
        component: (
            <Controller
                name={`${name}.ssid`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="SSID"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "salesman-code",
        component: (
            <Controller
                name={`${name}.salesmanCode`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Salesman Code"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "program-code",
        component: (
            <Controller
                name={`${name}.programCode`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Program Code"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "sb-number",
        component: (
            <Controller
                name={`${name}.sbNumber`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="SB Number"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "remark",
        component: (
            <Controller
                name={`${name}.remark`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Remark"
                        direction="vertical"
                        fullWidth
                        multiline
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
]

const ExternalRemarks = ({ name, control, errors = {}, readOnly }) => [
    {
        id: "remark",
        component: (
            <Controller
                name={`externalRemark`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="External Remarks"
                        direction="vertical"
                        fullWidth
                        multiline
                        readOnly={readOnly}
                    // error={error}
                    // helperText={error?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
]

export {
    ClientInfoFields,
    CustomerInfoFields,
    DeliveryMethodFields,
    PlanInfoFields,
    ProductInfoFields,
    PaymentFields,
    ExtraInfoFields,
    ExtraInfoFieldsForProduct,
    ExternalRemarks
};
