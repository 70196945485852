import Image from '../components/share/ImageV2';
import { Grid } from '@mui/material';
import Typography from './share/Typography';
import { ReactComponent as UploadIcon } from '../assets/upload-background.svg';
import { useForm, Controller } from "react-hook-form";
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import TextField from './share/TextField'
import styled from 'styled-components'
import Select from './share/Select';
import { useEffect } from 'react';
import _ from 'lodash';
import { ReactComponent as DeleteIcon } from '../assets/delete-icon.svg';

const FormContainer = styled(Grid)`
    && {
        > * {
            margin-bottom: 20px;
        }
    }
`

const CategoryList = [
    {
        name: 'All',
        id: 'all'
    },
    {
        name: 'Banner',
        id: 'banner',
    },
    {
        name: 'others',
        id: 'others'
    }
]

//Media used Only

function UploadImgItem({ data, control, onRemove, position, reset }) {

    useEffect(() => {
        if (data) {
            reset({
                newImageKey: data.imageUrl,
                title: data.title,
                description: data.description,
                category: _.find(CategoryList, { id: data.category })
            })
        }
    }, [data])

    return (
        <Grid container direction='column' style={{ padding: 30 }}>
            <Grid container direction='row' style={{ marginBottom: 30 }}>
                <Grid item xs>
                    <Typography variant="header3" >PNG, JPG and GIF files are allowed</Typography>
                </Grid>
                {position > 0 && 
                <Grid item>
                    <DeleteIcon  style={{ cursor: 'pointer' }} onClick={onRemove} />
                </Grid>}
            </Grid>
            
            <Grid item container direction='row'>
                <Grid item xs style={{ paddingRight: 30 }}>
                    <Controller
                        name={data ? "newImageKey" : `images.${position}.newImageKey`}
                        control={control}
                        render={({ field }) => (
                            <Image
                                height={650}
                                width={650}
                                Icon={UploadIcon}
                                // {...field}
                                // onUploaded={(uploadedImg) => {
                                //     field.onChange && field.onChange(uploadedImg.preview)
                                // }}
                                url={field.value}
                                dragable
                            />
                        )}
                    />
                </Grid>
                <FormContainer item xs container direction='column'>
                    <Grid item>
                        <Controller
                            name={data ? "title" : `images.${position}.title`}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    title="Title"
                                    direction='vertical'
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name={data ? "description" : `images.${position}.description`}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    title="Description"
                                    direction='vertical'
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name={data ? "category" : `images.${position}.category`}
                            control={control}
                            render={({ field }) => (
                                <Select
                                    title="Select Category"
                                    direction='vertical'
                                    fullWidth
                                    options={CategoryList}
                                    {...field}
                                    value={field.value || null}
                                    onChange={(e, value) => {
                                        field.onChange && field.onChange(value)
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </FormContainer>
            </Grid>
        </Grid>
    )
}

export default UploadImgItem