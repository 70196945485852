import { Grid, Breadcrumbs, Link, Box } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate, useSearchParams, Link as RouterLink } from 'react-router-dom';
import {
    Button,
    PageWrapper,
    Searchbar,
    Tab,
    Table,
    Typography,
    TableStatusFilter,
    FilterButton
} from '../../components/share';
import { alertDialogContext } from '../../context/AlertDialogProvider';
import { snackbarContext } from '../../context/SnackbarProvider';
import withTabSearchParams from '../../hoc/withTabSearchParams';
import { userContext } from '../../context/UserProvider';
import { getPermission } from '../../utils'
import { STATUS } from './index'

const margin = 10

//listTNC: sort

const TABS = [
    {
        name: "All",
        id: undefined
    },
    {
        name: "Mobile",
        id: 'plan'
    },
    {
        name: "Product",
        id: 'product'
    },
]

function OrderItemListPage({ updateTab, tabIndex, columns, permission = { canView: false, canModify: false } }) {
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);

    const { canView, canModify } = permission

    let [searchParams, setSearchParams] = useSearchParams();
    // const [selectMode, setSelectMode] = useState(false);

    const orderId = searchParams.get('orderId');
    const orderNumber = searchParams.get('orderNumber');

    const [filter, setFilter] = useState({
        // status: null,
        keyword: null,
    })

    const onClickTab = (index) => {
        updateTab(TABS[index], index);
        setFilter({})
    };

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        return (
            <Box display={"flex"} sx={{ flex: 1 }}>
                <Breadcrumbs aria-label="breadcrumb" separator=">" sx={{ flex: 1 }}>
                    <Link underline="hover" color="inherit" component={RouterLink} to="/order?type=orders">
                        <Typography variant="main" bold>All Orders</Typography>
                    </Link>
                    {orderNumber ?
                        <Typography variant="main" bold>{orderNumber && orderNumber !== "null" ? orderNumber : ""}</Typography>
                        : null
                    }
                </Breadcrumbs>
                {/* {
                    canView &&
                    <FilterButton
                        style={{ marginRight: margin }}
                        filters={[
                            {
                                type: 'date-range',
                                startDataKey: 'startDate',
                                endDataKey: 'endDate',
                            },
                            {
                                type: 'select',
                                dataKey: 'status',
                                options: STATUS
                            }
                        ]}
                        disabled={!canView}
                        value={filter}
                        onChange={(newFilterValue) => setFilter({ ...filter, ...newFilterValue })}
                    />
                } */}
                {/* <Button color="orange" style={{ marginRight: margin }}>Export</Button>
                <Button color="green" style={{ marginRight: margin }} onClick={handleOnOpenFilter}>Filter</Button> */}
            </Box>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                {/* <TableStatusFilter width={200} options={STATUS} value={filter.status} disabled={!canView} onChange={(newValue) => setFilter({ ...filter, status: newValue })} /> */}
                <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
                {/* <Checkbox label="Select" fullWidth={false} checked={selectMode} onChange={(event) => setSelectMode(event.target.checked)} /> */}
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                columns={columns}
                api={"/order/items"}
                apiParams={{
                    type: TABS[tabIndex].id,
                    orderId: orderId,
                    ...filter
                }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
            // onRowClick={(params) => {
            //     navigate(`/terms-and-conditions/${params.id}`)
            // }}
            // checkboxSelection={selectMode}
            />
        </PageWrapper>
    )
}

export default withTabSearchParams(OrderItemListPage, TABS)