import { useState, useEffect, useContext, useLayoutEffect } from 'react'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import {
  BrowserRouter
} from "react-router-dom";
import { QueryClient, QueryClientProvider, useQueries } from '@tanstack/react-query';
import UserProvider, { userContext } from './context/UserProvider';
import SnackbarProvider from './context/SnackbarProvider';
import AlertDialogProvider from './context/AlertDialogProvider';
import CompaniesProvider, { companiesContext } from './context/CompaniesProvider';
import VasProvider, { vasContext } from './context/VasProvider';
import TncProvider, { tncContext } from './context/TncProvider';
import AdminRoleProvider, { adminRoleContext } from './context/AdminRoleProvider';
import Routes from './Routes';
import Snackbar from './components/share/Snackbar';
import AlertDialog from "./components/AlertDialog";
import Login from './components/Login'
import { CircularProgress, Box } from '@mui/material';
import { fetchUser } from './apis/profile'
import { listCompany } from './apis/company'
import { listVas } from './apis/vas'
import { listTnc } from './apis/termsAndConditions'
import { listRole } from './apis/adminManagement'
import constants from './constants';
import _ from 'lodash'
import { getPermission } from './utils';

import '@fontsource/roboto';

export const theme = createTheme({
  palette: {
    common: {
      pageBgColor: "#FDFCEC",
    },
    primary: {
      main: "#6D5500",
      contrastText: "#fff"
    },
    secondary: {
      main: "#AEAEAE",
      light: "#F6F6F6",
      dark: "#E2E2E2"
    },
    error: {
      main: "#d32f2f"
    },
    info: {
      main: "#464646",
      light: "#AEAEAE",
      dark: "#000",
    },
    mainColor: {
      main: "#F2A000",
      light: "#FBEEAB"
    },
  },
});


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    },
  },
});

function Main() {

  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [user, setUser] = useContext(userContext);
  const [companies, setCompanies] = useContext(companiesContext);
  const [vasList, setVasList] = useContext(vasContext);
  const [tncList, setTncList] = useContext(tncContext);
  const [roles, setRoles] = useContext(adminRoleContext);


  useEffect(async () => {
    const access_token = localStorage.getItem('access_token')
    if (!window.location.hostname.includes('localhost') && constants.IS_DEV) {
      alert("IS DEV")
    }
    if (access_token) {
      let user
      if (!user) {
        user = await fetchUser();
        user && setUser({ ...user })
      }

      const [roleList] = await Promise.all([listRole()]);
      let mapRoleList = _.map(roleList, (item) => {
        const name = item.role === "fire_fighting" ? "Firefighting (SMEES)" : item.role === "it_account_managenment" ? "Account Management" : item.name
        return ({ 
          ...item, 
          _id: item.role, 
          name: name 
        })
      })
      setRoles(mapRoleList)
      // setCompanies(companyList)
      // setVasList(vasList)
      // if (user?.permissions?.tnc.length > 0) {
      //   const tncList = await listTnc()
      //   setTncList(tncList)
      // }
    }
    setIsLoggedIn(!access_token ? false : true);
  }, [])

  if (isLoggedIn === null) return (<Box display="flex" alignItems="center" justifyContent="center" height="100vh"><CircularProgress /></Box>)
  if (isLoggedIn === false) return (<Login />)

  return (
    <Routes />
  )

}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <SnackbarProvider>
            <AlertDialogProvider>
              <UserProvider>
                <CompaniesProvider>
                  <VasProvider>
                    <TncProvider>
                      <AdminRoleProvider>
                        <Main />
                        <Snackbar />
                        <AlertDialog />
                      </AdminRoleProvider>
                    </TncProvider>
                  </VasProvider>
                </CompaniesProvider>
              </UserProvider>
            </AlertDialogProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider >
  );
}

export default App;
