import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import {
    TableStatusFilter,
    PageWrapper,
    Typography,
    Button,
    Select,
    TextField,
    Form
} from '../../components/share';
import { snackbarContext } from '../../context/SnackbarProvider';
import { fetchAdmin, updateAdmin, createAdmin, listRole } from '../../apis/adminManagement';
import { useParams, useNavigate } from 'react-router-dom';
import { userContext } from '../../context/UserProvider';
import { adminRoleContext } from '../../context/AdminRoleProvider';
import { getPermission } from '../../utils'
import { Grid } from '@mui/material'
import constants from '../../constants';

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    overflow: auto;
`

export const STATUS = [
    {
        name: "Active",
        _id: "active",
    },
    {
        name: "Suspended",
        _id: "suspended",
    },
]

export const schema = Joi.object({
    costControlCentre: Joi.string().required(),
    corphqId: Joi.string().required(),
    givenName: Joi.string().required(),
    familyName: Joi.string().required(),
    // email: Joi.string().email({ tlds: false }).required(),
    email: Joi.string().pattern(/^\w+([-+.']\w+)*@?(pccw.com|hkcsl.com)$/, "name").required(),
    role: Joi.string().required(),
    corphqId: Joi.string().required(),
    status: Joi.string().optional()
}).messages({
    "any.required": "Required",
    "object.base": "Required",
    "string.empty": "Required",
    "string.domain": "Must contain a valid domain name",
    "string.email": "Must contain a valid email",
    "string.pattern.name": "Must contain a valid email"
});

export const FIELDS = ({ control, errors, roleList, readOnly }) => [
    {
        id: 'header',
        component: (
            <Typography variant="header" sx={{ marginBottom: 1.5 }}>Fields marked * are required</Typography>
        )
    }, { id: "spacing" },
    {
        id: "cost-control-centre",
        component: (
            <Controller
                name="costControlCentre"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="CCC*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.costControlCentre}
                        helperText={errors?.costControlCentre?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "corphqId",
        component: (
            <Controller
                name="corphqId"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Staff ID (e.g. 0123456)*"
                        direction="vertical"
                        fullWidth
                        readOnly={readOnly}
                        error={errors.corphqId}
                        helperText={errors?.corphqId?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "given-name",
        component: (
            <Controller
                name="givenName"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="First Name*"
                        direction="vertical"
                        fullWidth
                        error={errors.givenName}
                        helperText={errors?.givenName?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "family-name",
        component: (
            <Controller
                name="familyName"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Last Name*"
                        direction="vertical"
                        fullWidth
                        error={errors.familyName}
                        helperText={errors?.familyName?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "email",
        component: (
            <Controller
                name="email"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Staff email address*"
                        direction="vertical"
                        fullWidth
                        error={errors.email}
                        helperText={errors?.email?.message}
                        readOnly={readOnly}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "role",
        component: (
            <Controller
                name="role"
                control={control}
                render={({ field }) => {
                    return (
                        <Select
                            {...field}
                            title="Role*"
                            direction="vertical"
                            options={roleList}
                            inputValue={field?.value?.name || ""}
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            key={field?.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            error={errors.role}
                            helperText={errors?.role?.message}
                            disabled={readOnly}
                        />

                    )
                }
                }
            />
        )
    },
]

function AdminAddPage(props) {
    const navigate = useNavigate();

    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);
    const [roles] = useContext(adminRoleContext)

    const { canView, canModify } = getPermission(user?.permissions?.adminUser)
    // const { canView:canViewCreation, canModify:canModifyCreation } = getPermission(user?.permissions?.adminAccountCreation)

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset } = useForm({
        resolver: joiResolver(schema),
    });

    const mutation = useMutation({
        mutationFn: (payload) => createAdmin(payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            navigate('/admin');
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: "error",
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    const handleOnSubmit = (formValue) => {
        let payload = {
            status: 'active'
        };
        _.forEach(dirtyFields, (value, key) => {
            payload[key] = formValue[key]
        })
        mutation.mutate(payload)
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Admin Management > Add New`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
                {/* <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                        <TableStatusFilter
                            options={STATUS}
                            style={{ margin: `0px 10px` }}
                            titleStyle={{ color: "#6D5500" }}
                            {...field}
                        />
                    )}
                /> */}
                {/* <Typography variant="header3" sx={{ mr: 1 }}>Edited on {dayjs().format(constants.DATE_TIME_FORMAT)}</Typography> */}
                <SaveButton loading={mutation.isLoading} disabled={!canModify} onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>
            </Grid>
        )
    }

    console.log("errors", errors)

    return (
        <PageWrapper
            loading={mutation.isLoading}
            showBackButton
            renderHeader={Header}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Form fields={FIELDS({ control, errors, roleList: roles  })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default AdminAddPage