import { alertDialogContext } from '../context/AlertDialogProvider';
import { useContext, forwardRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog() {
    const [dialog, setDialog] = useContext(alertDialogContext);

    const handleClose = () => {
        setDialog({ open: false });
    };

    const onClickOK = () => {
        dialog && dialog.handleOnClickOK && dialog.handleOnClickOK()
        handleClose()
    }

    return (
        <Dialog
            open={dialog?.open || false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >

            <DialogContent>
                <DialogTitle id="alert-dialog-title">
                    {dialog?.title}
                </DialogTitle>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={onClickOK} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}