import { useContext } from "react";
import styled from "styled-components";
import Search from './Search';
import Avatar from '@mui/material/Avatar';
import Typography from './Typography'
import { userContext } from "../../context/UserProvider";

const Container = styled.div`
    height: 80px;
    position: relative;
`

const Content = styled.div`
    background-color: #fff;
    box-shadow: 0px 2px 29px -12px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%
    position: absolute;
    padding-right: 20px;
`

const Text = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
`

function Header() {

    const [user, setUser] = useContext(userContext);

    return (
        <Container>
            <Content>
                <Avatar sx={{ mr: 2 }}>{user?.email?.[0] || ""}</Avatar>
                <div>
                    <Text>{`${user.givenName || ""} ${user.familyName || ""}`}</Text>
                    <Text>{user?.email || ""}</Text>
                </div>
            </Content>
        </Container>
    )
}

export default Header