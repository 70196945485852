import SortableList from "../share/SortableList";
import Image from '../share/ImageV2'
import dayjs from "dayjs";
import constants from '../../constants'
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typography } from "../share";

const columns = [
    {
        dataKey: '_id',
        label: 'Seq',
        width: 100,
        sortable: false
    },
    {
        dataKey: 'imageUrl',
        label: 'Preview',
        width: 250,
        sortable: false,
        renderCell: (params) => {
            return (
                <Image
                    height={86}
                    width={168}
                    url={params.banner.desktopImageUrlEn}
                    readOnly
                    disableClick
                />
            )
        }
    },
    {
        dataKey: 'allowedCompany',
        label: 'Company Name',
        width: 250,
        renderCell: (params) => {
            return (
                <Typography>{params?.banner?.companyNames?.map(item => item.name).join(', ')}</Typography>
            )
        }
    },
    {
        dataKey: 'lineOne',
        label: 'Line 1',
        width: 160,
        sortable: false,
        renderCell: (params) => {
            return (
                <Typography>{params?.banner?.animationLine1?.en}</Typography>
            )
        }
    },
]

function BannerSortableList({ control }) {

    // const [values, setValues] = useState([]);

    // useEffect(() => {
    //     if (data) {
    //         setValues(data)
    //     }
    // }, [data])

    return (
        <Controller
            name="banner"
            control={control}
            render={({ field }) => (
                <SortableList
                    // data={values}
                    data={field?.value || []}
                    columns={columns}
                    rowHeight={120}
                    headerStyle={{
                        background: "#FBEEAB",
                        borderRadius: 5
                    }}
                    rowStyle={{
                        borderBottom: `1px dashed #E2EBF4`,
                    }}
                    onSort={(oldIndex, newIndex) => {
                        const newItems = field.value.slice();
                        newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);
                        // setValues(newItems);
                        field.onChange(newItems)
                    }}
                />
            )}
        />

    )
}

export default BannerSortableList