import { request } from './index';
import constants from '../constants';

const listCategory = () => {
    return new Promise((resolve, reject) => {

        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/tnc/category`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const listTnc = () => {
    return new Promise((resolve, reject) => {

        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/tnc`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const fetchTnc = (id) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/tnc/${id}`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const createTnc = (payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/tnc`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateTnc = (id, payload) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/tnc/${id}`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const deleteTnc = (id) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "delete",
            url: `${constants.API_ENDPOINT}/tnc/${id}`,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

export {
    listCategory,
    listTnc,
    fetchTnc,
    createTnc,
    deleteTnc,
    updateTnc
}
