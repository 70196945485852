import React, { useCallback, useMemo, useState, useRef, useEffect, useLayoutEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import Typography from './Typography'
import _ from 'lodash';

const HeaderWrapper = styled.div`
    display: flex; 
    flex-wrap: nowrap; 
    ${props => props.height && `height: ${props.height}px;`}
    padding: 2px 0px;
`

export const Column = styled(TableCell)`
    && {
        display: flex;
        ${props => props.width && `width: ${props.width}px;`}
        ${props => props.height && `height: ${props.height}px;`}
        ${props => props.flexGrow && `flex-grow: ${props.flexGrow};`}
        align-items: center;
        box-sizing: border-box;
        padding: 0px 20px;
        margin: 4px 0px;
        overflow: hidden;
        border: none;
    }
`

export const ListItem = styled.li`
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 0px;
    min-height: 50px;
    ${props => props.height && `height: ${props.height}px;`}
`

const CustomPaper = styled(Paper)`
    && {
        border-radius: 0;
        border: 1px solid #AEAEAE;
        box-shadow: none;
    }
`

const HeaderTableCell = styled(TableCell)`
    && {
        border-color: #AEAEAE;
    }
`

function DataTable({ data, columns, renderCustomListItem }) {

    return (
        <TableContainer component={CustomPaper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columns.map((item, columnIndex) => {
                            return (
                                <HeaderTableCell 
                                    key={item.dataKey} 
                                    width={item?.width}
                                    sx={{
                                        backgroundColor: 'mainColor.light',
                                        color: 'primary.main'
                                    }}
                                >
                                    {item.label}
                                </HeaderTableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, rowIndex) => (
                        <TableRow
                            key={row?._id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {columns.map((item, columnIndex) => {
                                if (item.renderCell) {
                                    return (
                                        <TableCell key={`body-${item.dataKey}`}>
                                            {item.renderCell(row, rowIndex)}
                                        </TableCell>
                                    )
                                }
                                return (
                                    <TableCell>{row?.[item.dataKey] || ""}</TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                    {renderCustomListItem && 
                    <TableRow>
                        <TableCell>{renderCustomListItem()}</TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DataTable