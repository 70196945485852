import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Table from '../../components/share/Table';
import PageWrapper from '../../components/share/PageWrapper';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';

const margin = 10;

export const CategoryList = [
    {
        id: "delivery",
        name: "Delivery and Shipping Fees Inquires"
    },
    {
        id: "account",
        name: "Account Login"
    },
    {
        id: "payment",
        name: "Payment and Return Policy"
    }
]

function TutorialPage(props) {
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);

    const columns = useMemo(() => {
        return [
            { field: 'name', headerName: 'Tab Name', flex: 1, sortable: false, }
        ]
    }, [])

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Shopping Tutorial`}</Typography>
            </>
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCardHeader={() => (<div />)}
        >
            <Table
                style={{ margin: `20px 20px 20px 20px` }}
                columns={columns}
                data={CategoryList}
                sortingMode="server"
                setSnackbar={setSnackbar}
                getRowId={(row) => row.id}
                onRowClick={(params) => {
                    console.log("onRowClick")
                    navigate(`/shopping-tutorial/detail?tab=${params.id}`)
                }}
            />
        </PageWrapper>
    )
}

export default TutorialPage