import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { Grid, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import { Typography, Button } from '../../components/share';
import PageWrapper from '../../components/share/PageWrapper';
import { listMedia } from '../../apis/medias';
import UploadImgItem from '../../components/UploadImgItem';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

const AddButton = styled(Button)`
    && {
        padding-left: 30px;
        padding-bottom: 20px;
        display: inline-block;
    }
`

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

const schema = Joi.object({
    images: Joi.array().items(
        Joi.object({
            newImageKey: Joi.any(),
            title: Joi.string(),
            description: Joi.string(),
            category: Joi.object(),
        })
    )
});

function MediaAddPage() {

    const navigate = useNavigate();

    let { categoryId } = useParams();

    const { control, register, formState: { errors }, handleSubmit } = useForm({
        resolver: joiResolver(schema),
        defaultValues: {
            images: [
                {
                    newImageKey: "",
                    category: "",
                    title: "",
                    description: ""
                }
            ]
        }
    });
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: 'images',
    });

    const Header = () => {
        return (
            <Typography bold style={{ flex: 1 }}>{`Media > ${categoryId} > Add`}</Typography>
        )
    }

    const handleOnSubmit = (data) => {
        console.log("onSubmit", data)
    }

    const CardHeader = () => {
        return (
            <SaveButton onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>
        )
    }

    console.log("errors", errors)

    return (
        <PageWrapper
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <div style={{ height: '100%', overflow: 'auto' }}>
                <Grid container direction='column'>
                    {
                        fields.map((item, position) => {
                            return (
                                <UploadImgItem
                                    {...item}
                                    position={position}
                                    control={control}
                                    onRemove={() => remove(position)}
                                />
                            )
                        })
                    }
                </Grid>
                <Button variant="underline" color="neon" onClick={insert}>+ Add More Image</Button>
            </div>
        </PageWrapper>
    )
}

export default MediaAddPage