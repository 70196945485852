import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { snackbarContext } from '../../../context/SnackbarProvider';
import { userContext } from '../../../context/UserProvider';
import MobileOrderComponent from './mobile';
import ProductOrderComponent from './product';
import {
    useQuery,
} from '@tanstack/react-query'
import { fetchOrderDetail } from '../../../apis/order'
import Joi from 'joi';
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
    TextField,
    Typography,
} from '../../../components/share';
import dayjs from 'dayjs';
import constants from '../../../constants'
import { getPermission } from '../../../utils'

export const STATUS = [
    {
        _id: "pending",
        name: "Pending",
        disabled: true
    },
    {
        _id: "paymentSuccess",
        name: "Payment Success",
        disabled: true
    },
    {
        _id: "paymentFailure",
        name: "Payment Failure",
        disabled: true
    },
    {
        _id: "pendingDocument",
        name: "Pending Document",
        disabled: true
    },
    {
        _id: "documentSubmitted",
        name: "Submitted", //TODO: product: Submitted, mobile: Document Submitted
    },
    {
        _id: "verified",
        name: "Verified",
    },
    {
        _id: "cancelled",
        name: "Cancelled",
    },
    {
        _id: "completed",
        name: "Completed",
    },
]

export const activityLogSchema = Joi.object({
    remark: Joi.string().empty(""),
    item: Joi.string().empty(""),
    reason: Joi.string().required(),
});

export const generalActivityLogColumns = (control, name) => [
    {
        dataKey: 'remark',
        label: 'Remarks',
        flexGrow: 2,
        renderCell: (item, index) => {
            return (
                <Controller
                    name={`${name}.${index}.remark`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextField
                                {...field}
                                fullWidth
                                readOnly={item.canRemove ? false : true}
                                multiline
                            />
                        )
                    }}
                />
            )
        }
    },
    {
        dataKey: 'item',
        label: 'Item',
        flexGrow: 2,
        renderCell: (item, index) => {
            return (
                <Controller
                    // name={`remarks.${index}.item`}
                    name={`${name}.${index}.item`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextField
                                {...field}
                                fullWidth
                                readOnly={item.canRemove ? false : true}
                                multiline
                            />
                        )
                    }}
                />
            )
        }
    },
    {
        dataKey: 'reason',
        label: 'Reason(s)',
        flexGrow: 2,
        renderCell: (item, index) => {
            return (
                <Controller
                    name={`${name}.${index}.reason`}
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextField
                                {...field}
                                fullWidth
                                readOnly={item.canRemove ? false : true}
                                multiline
                            />
                        )
                    }}
                />
            )
        }
    },
    {
        dataKey: 'updatedBy',
        label: 'Last Edit',
        width: 150,
        renderCell: (item, index) => {
            return (
                <Typography>{item.updatedBy.email}</Typography>
            )
        }
    },
    {
        dataKey: 'createdAt',
        label: 'Time',
        width: 200,
        renderCell: (item, index) => {
            return (
                <Typography>{dayjs().format(constants.DATE_TIME_FORMAT)}</Typography>
            )
        }
    },
]

export const DefRemark = {
    remarks: '',
    item: "",
    updatedBy: "",
}

function MobileDetail(props) {

    const navigate = useNavigate();
    const [user, setUser] = useContext(userContext);
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    let { orderItemId } = useParams();

    const permission = getPermission(user?.permissions?.orderItem)
    const pIIPermission = getPermission(user?.permissions?.customerProfilePII)

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["orderDetail", { orderItemId }],
        queryFn: async () => await fetchOrderDetail(orderItemId),
    });

    const isMobie = data?.data?.type === "plan" ? true : false;

    if (isMobie) {
        return (
            <MobileOrderComponent {...props} data={data?.data} permission={permission} pIIPermission={pIIPermission} isLoading={isLoading} />
        )
    }

    return (
        <ProductOrderComponent {...props} data={data?.data} permission={permission} pIIPermission={pIIPermission} isLoading={isLoading} />
    )
}

export default MobileDetail