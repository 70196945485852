import { Autocomplete, Checkbox, InputAdornment, IconButton, TextField, Box, } from "@mui/material";
import ComponentContainer from "./ComponentContainer";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { CustomTextField, EndAdorment } from './Select'
// import { TextField, Box, IconButton } from "@mui/material";
import { styled as styledMui } from '@mui/material/styles';
import styled from 'styled-components'
import Typography from './Typography'
import CustomTextField from './TextField'
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { request, axios } from '../../apis'
import constants from '../../constants';
import { useState, useRef, useEffect } from 'react'
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import _ from "lodash"
import CircularProgress from '@mui/material/CircularProgress';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Underline = styled.span`
    && {
        text-decoration: underline;
        margin-left: 10px;
    }
`

const Container = styled.div`
    > * {
        padding-top: 10px;
    }
`

function AutoCompleteWithList({
    direction,
    title,
    titleStyle,
    error,
    helperText,

    api,
    apiParams,

    options,
    onError,
    readOnly,
    getOptionLabel,
    value = [],
    onChange,
}) {

    const [selected, setSelected] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const { data: response, isLoading, refetch } = useQuery({
        queryKey: [api, { params: { ...apiParams, keyword: inputValue } }],
        queryFn: () => axios.get(`${constants.API_ENDPOINT}${api}`, {
            params: {
                ...apiParams,
                keyword: inputValue !== "" ? inputValue : undefined
            }
        }).then((res) => res.data),
        retry: false,
        enabled: api ? true : false,
        onError: (error) => {
            onError && onError(error)
        },
    })

    useEffect(() => {
        if (value && value.length > 0) {
            setSelected(value)
        }
    }, [value])

    const onRemove = (v) => {
        const tmpArr = [...value]
        const index = _.findIndex(value, {_id: v._id})
        if (index > -1) {
            tmpArr.splice(index, 1);
            setSelected(tmpArr)
            onChange && onChange(tmpArr)
        }
    }

    const handleOnChange = () => {
        onChange && onChange(selected)
    }

    const renderReadOnly = () => {
        if (api && isLoading) {
            return <CircularProgress size={20} />
        }
        if (!selected || !selected?.length) {
            return (
                <CustomTextField 
                    readOnly
                    value={""}
                />
            )
        }
        return (
            <>
                {
                    selected.map((item, i) => {
                        const display = getOptionLabel(item)
                        return (
                            <Box display='flex' flexDirection='row' alignItems='center' key={`${display}-${i}`}>
                                <Typography variant="secondary">
                                    <Underline>{display}</Underline>
                                </Typography>
                            </Box>
                        )
                    })
                }
            </>
        )
    }

    return (
        <ComponentContainer title={title} titleStyle={titleStyle} error={error} helperText={helperText} direction={direction}>
            {!readOnly &&
                <Autocomplete
                    multiple
                    value={selected}
                    onChange={(e, newValue, reason) => {
                        setSelected(newValue)
                    }}
                    onInputChange={(e, newValue) => {
                        setInputValue(newValue)
                        refetch()
                    }}
                    onClose={handleOnChange}
                    loading={isLoading}
                    options={response?.data || options || []}
                    forcePopupIcon={false}
                    getOptionLabel={getOptionLabel}
                    disableCloseOnSelect
                    selectOnFocus={false}
                    disableClearable
                    renderTags={() => null}
                    disabled={readOnly}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    renderOption={(props, option, { selected }) => {
                        const display = getOptionLabel(option)
                        return (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {display}
                            </li>
                        )
                    }}
                    style={{ minWidth: 300 }}
                    sx={{
                        "& .MuiAutocomplete-endAdornment": {
                            marginRight: 0.5
                        },
                        backgroundColor: "#F6F6F6",
                        borderRadius: 5
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Select..."
                                sx={{
                                    height: 43,
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    style: {
                                        ...params.inputProps.style,
                                        padding: `9px 10px`,
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: {
                                        ...params.InputProps.style,
                                        backgroundColor: "#F6F6F6",
                                        borderRadius: 5
                                    },
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                height: '100%',
                                                width: 40,
                                                borderLeft: 'solid',
                                                borderWidth: 1,
                                                borderColor: 'primary.dark',
                                                display: 'flex',
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginLeft: 0
                                            }}
                                        >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onMouseDown={params?.inputProps?.onMouseDown}
                                                edge="end"
                                                sx={{
                                                    marginRight: 0,
                                                    color: 'primary.dark'
                                                }}
                                            >
                                                <KeyboardArrowDownIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )
                    }}
                />
            }
            <Container>
                {
                    readOnly ?
                        renderReadOnly() :
                        selected.map((item, i) => {
                            const display = getOptionLabel(item)
                            return (
                                <Box display='flex' flexDirection='row' alignItems='center' key={`${display}-${i}`}>
                                    <Typography color="#BB9202">
                                        {`+`}
                                        <Underline>{display}</Underline>
                                    </Typography>
                                    <IconButton sx={{ marginLeft: '4px' }} onClick={() => onRemove(item)}>
                                        <DeleteIcon  />
                                    </IconButton>
                                </Box>
                            )
                        })
                }
            </Container>
        </ComponentContainer>
    )
}

export default AutoCompleteWithList