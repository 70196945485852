import { useState, useRef, useEffect } from 'react';
import styled from "styled-components";
import Typography from './Typography';
import MuiTypography from '@mui/material/Typography'
import Button from './Button';
import { styled as styledMui } from '@mui/material/styles';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: ${props => props.height}px;
    position: relative;
    width: 100%;
`

const Divider = styledMui('div')(
    ({ theme, isTab, isSelected }) => `
    height: 4px;
    width: 100%;
    background-color: ${isTab && isSelected ? theme.palette.primary.main : theme.palette.mainColor.light};
  `,
);

const Container = styled.div`
    // position: absolute; 
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: calc(100% - 20px);
    flex: 1;
    margin-left: 20px;
    position: relative;
    overflow-y: hidden;
`

const ScrollContainer = styled.div`
    display: flex;
    overflow: auto;
    flex-direction: row;
    flex: 1;
    position: relative;
    top: 7px;
    z-index: 10;
    overflow-y: hidden;

    ::-webkit-scrollbar {
        display: none;
    }
`

const TabContainer = styled.div`
    position: relative;
    background-color: #fff;
    cursor: pointer;
    // padding-left: 20px;
`

const Label = styledMui(MuiTypography)(
    ({ theme, selected }) => `
    padding: 20px 20px 10px 20px;
    color: ${selected ? theme.palette.primary.main : theme.palette.secondary.main};
    :first-letter {
        text-transform: uppercase;
      }
  `,
);

function useHorizontalScroll() {
    const elRef = useRef();
    useEffect(() => {
        const el = elRef.current;
        if (el) {
            const onWheel = e => {
                if (e.deltaY == 0) return;
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + e.deltaY,
                    behavior: "smooth"
                });
            };
            el.addEventListener("wheel", onWheel);
            return () => el.removeEventListener("wheel", onWheel);
        }
    }, []);
    return elRef;
}

function Tab({ tabs, selected, onChange, renderButton, height = 65, hideScrollbar }) {

    const scrollRef = useHorizontalScroll();

    return (
        <Root height={height}>
            <Container>
                <ScrollContainer ref={scrollRef}>
                    {
                        tabs.map((item, i) => {
                            const isSelected = selected === i;
                            return (
                                <TabContainer key={item.name} onClick={() => onChange(i)}>
                                    <Label selected={isSelected} sx={{ whiteSpace: "nowrap" }}>{item.name}</Label>
                                    <Divider isTab isSelected={isSelected} />
                                    {/* {isSelected && <Divider isTab />} */}
                                </TabContainer>
                            )
                        })
                    }
                </ScrollContainer>
                {renderButton && renderButton()}
            </Container>
            <Divider sx={{ position: 'absolute' }} />
        </Root>
    )
}

export default Tab