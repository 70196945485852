import { useState, useContext, useCallback, useImperativeHandle, useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Form } from '../../components/share';
import {
    DeliveryFields,
} from './formFields';
import { request } from '../../apis/index'
import constants from '../../constants';
import {
    updateDeliveryConfig,
} from '../../apis/globalSettings'
import { getDirtyValues } from '../../utils'
import { CircularProgress, Box } from '@mui/material';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

const schema = Joi.object({
    estimatedDeliveryDay: Joi.number(),//.empty(''),
    estimatePickupDay: Joi.number(),//.empty(''),
    freeShippingMinSpent: Joi.number(),//.empty(''),
    deliveryFee: Joi.number()//.empty('')
}).messages({
    "any.required": "Required",
    "number.base": "Must be a number",
});


const getResetData = ({ data }) => {
    return {
        estimatedDeliveryDay: _.find(data, { keyPath: "delivery.estimatedDeliveryDay" })?.value,
        estimatePickupDay: _.find(data, { keyPath: "delivery.estimatePickupDay" })?.value,
        freeShippingMinSpent: _.find(data, { keyPath: "delivery.freeShippingMinSpent" })?.value,
        deliveryFee: _.find(data, { keyPath: "delivery.deliveryFee" })?.value
    }
}

const DeliverySystemConfiguration = forwardRef(({ setSnackbar, permission }, ref) => {

    const { data, error: apiError, isError, isLoading } = useQuery({
        queryKey: ['delivery'],
        queryFn: () => request({
            method: 'get',
            url: `${constants.API_ENDPOINT}/systemConfig/delivery`
        }),
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error?.result?.errorMessage?.en}`
            })
        },
    })

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, setValue } = useForm({
        resolver: joiResolver(schema),
    });

    useEffect(() => {
        if (data?.data) {
            reset(getResetData({ data: data?.data }))
        }
    }, [data])

    const deliveryMutation = useMutation({
        mutationFn: (payload) => updateDeliveryConfig(payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            reset(getResetData({ data }))
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        if (_.isEmpty(dirtyFields)) {
            return setSnackbar({
                type: "success",
                message: "Success"
            })
        }
        const dirty = getDirtyValues(dirtyFields, formValue);
        deliveryMutation.mutate(dirty)
    }

    useImperativeHandle(ref, () => ({
        onSubmit: () => handleSubmit(handleOnSubmit)()
    }));

    if (isLoading) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="100％">
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Form fields={DeliveryFields({ control, errors: errors, readOnly: permission.canModify ? false : true })} p={`14px 0px`} />
    )
})

export default DeliverySystemConfiguration