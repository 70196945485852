import { useState, useContext, useCallback, useMemo, useEffect, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import {
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import Tab from '../../components/share/Tab';
import PageWrapper from '../../components/share/PageWrapper';
import { listMedia } from '../../apis/medias';
import withTabSearchParams from '../../hoc/withTabSearchParams'
import { styled as styledMui } from '@mui/material/styles';

const Container = styled(Grid)`
    && {
        padding: 30px;

        :not(:first-child)
        :not(:last-child) {
            margin-right: 20px;
        }
    }
`


const GridItem = styledMui(Grid)(
    ({ theme }) => `
    && {
        height: 197px;
        width: 197px;
        background-color: #F4F4F4;
        border: 1px solid ${theme.palette.secondary.main};
        border-radius: 5px;
        cursor: pointer;
    }
  `,
);

const StyledImg = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`

const TABS = [
    { name: "All", id: "all" },
    { name: "Banner", id: "banner" },
    { name: "Others", id: "others" }
]

function MediaPage({ updateTab, tabIndex }) {

    const navigate = useNavigate();

    const queryClient = useQueryClient();


    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["media", { category: TABS[tabIndex].id }],
        queryFn: listMedia
    });

    const onClickTab = (index) => {
        updateTab(TABS[index], index)
    }

    const Header = () => {
        return (
            <Typography bold style={{ flex: 1 }}>{`Media > ${TABS[tabIndex].name}`}</Typography>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    return (
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                margin: `0px 20px 14px 20px`,
                            }}
                            onClick={() => navigate(`/media/${TABS[tabIndex].id}/add`)}
                            type="add"
                        >
                            Add New
                        </Button>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >
            <Container container direction='row'>
                {
                    (data || []).map((item) => {
                        return (
                            <GridItem
                                item
                                xs
                                key={item._id}
                                onClick={() => navigate(`/media/${item._id}`)}
                            >
                                <StyledImg
                                    src="https://upload.wikimedia.org/wikipedia/en/d/d1/Toad_3D_Land.png"
                                />
                            </GridItem>
                        )
                    })
                }
            </Container>

        </PageWrapper>
    )
}

export default withTabSearchParams(MediaPage, TABS)