import {
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import Joi from 'joi';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { createPlan, fetchPlan } from '../../apis/plan';
import Summary from '../../components/mobile/Confirmation';
import {
    Button,
    Form,
    PageWrapper,
    PositionedMenu,
    Progressbar,
    PublishPeriodDialog,
    Typography
} from '../../components/share';
import { snackbarContext } from '../../context/SnackbarProvider';
import { companiesContext } from '../../context/CompaniesProvider';
import { vasContext } from '../../context/VasProvider';
import { tncContext } from '../../context/TncProvider';
import { userContext } from '../../context/UserProvider';
import { getDirtyValues, removeEmptyElements, removeId, toIds, getPermission } from '../../utils';
import {
    RecommendFields,
    Step1Fields,
    Step2Fields,
    TncFields,
    VasFields
} from './formFields';
import constants from '../../constants'

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        min-width: 80px;
        margin: 10px 8px;
        height: 30px;
    }
`
const RowDirectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: auto;
`

export const schema = Joi.object({
    status: Joi.any(),
    publishStartDate: Joi.any(),
    publishEndDate: Joi.any(),
    step1: Joi.object({
        company: Joi.string().empty("").allow(null),
        promotionTitle: Joi.object({
            en: Joi.string().empty(""),
            zh: Joi.string().empty(""),
        }),
        flipCardLabel: Joi.object({
            en: Joi.string().required(),
            zh: Joi.string().required(),
        }),
        flipCardIconImage: Joi.object().required(),
        planName: Joi.object({
            en: Joi.string().required(),
            zh: Joi.string().required(),
        }),
        localData: Joi.object({
            en: Joi.string().required(),
            zh: Joi.string().required(),
        }),
        localVoiceMins: Joi.object({
            en: Joi.string().required(),
            zh: Joi.string().required(),
        }),
        originalPrice: Joi.string().required(),
        discountedPrice: Joi.number().allow(null),
        planId: Joi.string().empty(""),
        cardFront: Joi.array().items(
            Joi.object({
                image: Joi.object().required(),
                description: Joi.object({
                    en: Joi.string().empty(""),
                    zh: Joi.string().empty(""),
                }),
                sort: Joi.number(),
            })
        ),
        cardBack: Joi.array().items(
            Joi.object({
                image: Joi.object().required(),
                description: Joi.object({
                    en: Joi.string().empty(""),
                    zh: Joi.string().empty(""),
                }),
                sort: Joi.number(),
            })
        )
    }),
    step2: Joi.object({
        planDetailsInclude: Joi.array().items(
            Joi.object({
                image: Joi.any(),
                title: Joi.object({
                    en: Joi.string().empty(""),
                    zh: Joi.string().empty(""),
                }),
                description: Joi.object({
                    en: Joi.string().empty(""),
                    zh: Joi.string().empty(""),
                }),
                remark: Joi.object({
                    en: Joi.string().empty(""),
                    zh: Joi.string().empty(""),
                }),
                sort: Joi.number(),
            })
        ),
        contractPeriodDetails: Joi.array().items(
            Joi.object({
                promotionTitle: Joi.object({
                    en: Joi.string().empty(""),
                    zh: Joi.string().empty(""),
                }),
                period: Joi.string().empty("").required(),
                discountedPrice: Joi.number().positive().allow(null),
                originalPrice: Joi.number().positive().allow(null),
            })
        ),
        quantityDescription: Joi.object({
            en: Joi.string().empty(""),
            zh: Joi.string().empty(""),
        }).required(),
        quantityMin: Joi.number().required(),
        quantityMax: Joi.number().min(Joi.ref('quantityMin')).required().messages({'number.min': "Must be greater than Minimum No.", "any.ref": "Limit references Minimum No. which must be a number" }),
    }),
    step3: Joi.object({
        // tncs: Joi.array().required(),
        beforeBuyTncs: Joi.array().required(),
        overallTncs: Joi.array().required(),
        detailPageTncs: Joi.array().required(),
        remark: Joi.object({
            en: Joi.string().required(),
            zh: Joi.string().required(),
        }),
    }),
    step4: Joi.object({
        freeVas: Joi.array(),
        freeVasMax: Joi.string().empty(""),
        // bundleFreeVas: Joi.array().required(),
        paidVas: Joi.array(),
    }),
    step5: {
        recommend: Joi.array()
    }
}).messages({
    "any.required": "Required",
    "number.base": "Must be a number",
    "object.base": "Required",
    "string.empty": "Required",
    "array.min": "Must contain at least {#limit} items",
});

export const Tabs = [
    {
        name: "Flip Card",
        id: "step1"
    },
    {
        name: "Contact Details",
        id: "step2"
    },
    {
        name: "T&C And Remark",
        id: "step3"
    },
    {
        name: "VAS",
        id: "step4"
    },
    {
        name: "Recommend",
        id: "step5"
    },
    // {
    //     name: "Control",
    //     id: "control"
    // }
]

export const DefFrontCardFields = {
    description: {
        en: "",
        zh: ""
    },
}

export const DefPlanDetailFields = {
    title: {
        en: "",
        zh: ""
    },
    description: {
        en: "",
        zh: ""
    },
    remark: {
        en: "",
        zh: ""
    },
}

export const DefContractPeriodDetailFields = {
    promotionTitle: {
        en: "",
        zh: ""
    },
    period: undefined,
    discountedPrice: null,
    originalPrice: null
}

function AddMobilePlan(props) {

    const [step, setStep] = useState(0);
    const [publishDialog, setPublishDialog] = useState({ open: false })
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [companies] = useContext(companiesContext);
    const [vasList] = useContext(vasContext);
    const [tncList] = useContext(tncContext);
    const [showSummary, setShowSummary] = useState(false);
    const [user, setUser] = useContext(userContext);
    let [searchParams, setSearchParams] = useSearchParams();

    const { canView, canModify } = getPermission(user?.permissions?.plan)

    const planId = searchParams.get('planId');

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, getValues, setValue, setError, clearErrors } = useForm({
        // resolver: joiResolver(schema),
        defaultValues: {
            step1: {
                cardFront: [
                    { ...DefFrontCardFields },
                    { ...DefFrontCardFields },
                    { ...DefFrontCardFields }
                ],
                cardBack: [{ ...DefFrontCardFields }]
            },
            step2: {
                planDetailsInclude: [{ ...DefPlanDetailFields }],
                contractPeriodDetails: [{ ...DefContractPeriodDetailFields }],
                quantityDescription: {}
            },
            step3: {
                remark: {}
            },
            step4: {},
            step5: {
                recommend: Array.from({ length: 3 }, (v, i) => `${i}`)
            }
        },
    });

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["planDetail", { planId }],
        queryFn: async () => await fetchPlan(planId),
        enabled: Boolean(planId && canModify),
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    });

    const mutation = useMutation({
        mutationFn: (payload) => createPlan(payload),
        onSuccess: (data) => {
            navigate(`/mobile`);
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        },
    })

    useEffect(() => {
        if (planId && data) {
            const defRecommend = Array.from({ length: 3 }, (v, i) => `${i}`)
            const recommendPlanList = _.map(data?.recommendPlans, (item) => ({ category: 'plan', id: item._id }))
            const recommendProductList = _.map(data?.recommendProducts, (item) => ({ category: 'product', id: item._id }))
            const recommendList = _.concat(recommendPlanList, recommendProductList, defRecommend).slice(0, 3);
            reset({
                step1: {
                    company: data?.company?._id,
                    promotionTitle: data.promotionTitle || {},
                    flipCardLabel: data?.flipCardLabel || {},
                    flipCardIconImage: data?.flipCardIconImage,
                    planName: data?.planName || {},
                    localData: data?.localData || {},
                    localVoiceMins: data?.localVoiceMins || {},
                    discountedPrice: data?.discountedPrice || null,
                    originalPrice: data?.originalPrice ? data?.originalPrice?.toString() || null : null,
                    planId: data.planId,
                    cardFront: data?.cardFront ? removeId(data.cardFront) : [],
                    cardBack: data?.cardBack ? removeId(data.cardBack) : [],
                },
                step2: {
                    planDetailsInclude: data?.planDetailsInclude ? removeId(data.planDetailsInclude) : [],
                    contractPeriodDetails: data?.contractPeriodDetails ? removeId(data.contractPeriodDetails) : [],
                    quantityDescription: data?.quantityDescription || {},
                    quantityMin: data.quantityMin,
                    quantityMax: data.quantityMax
                },
                step3: {
                    detailPageTncs: data?.detailPageTncs ? data?.detailPageTncs : [],
                    overallTncs: data?.overallTncs ? data?.overallTncs : [],
                    beforeBuyTncs: data?.beforeBuyTncs ? data?.beforeBuyTncs : [],
                    remark: data?.remark || {}
                },
                step4: {
                    freeVasMax: data?.freeVasMax,
                    freeVas: data?.freeVas,
                    paidVas: data?.paidVas,
                },
                step5: {
                    recommend: recommendList
                }
            })
        }
    }, [data, planId])

    const validateForm = () => {
        clearErrors();
        const values = getValues()
        const result = schema.validate(values, { abortEarly: false });
        if (result.error) {
            _.forEach(result.error.details, (item) => {
                setError(item.context.label, {message: item.message})
            })
            setValue('status', "draft", { shouldDirty: true })
            setValue('publishStartDate', undefined, { shouldDirty: true })
            setValue('publishEndDate', undefined, { shouldDirty: true })
            setShowSummary(false);
            setSnackbar({
                type: 'error',
                message: constants.INVALID_INPUT_ERR_MSG
            })
        } else {
            handleSubmit(handleOnSubmit)()
        }
    }

    const handleOnSubmit = (formValue) => {
        // console.log("formValue", formValue);
        const dirty = planId ? formValue : getDirtyValues(dirtyFields, formValue);
        let payload = { status: "draft" }
        for (const property in dirty) {
            if (property === "publishStartDate" || property === "publishEndDate") {
                if (dirty[property]) {
                    payload[property] = dirty[property]
                    payload['status'] = 'active'
                }
            } else if (typeof dirty[property] !== 'object'){
                payload[property] = dirty[property]
            } else {
                payload = { ...payload, ...dirty[property] };
            }
        }
        _.forEach(payload, (item, key) => {
            if (key === 'recommend') {
                let tmpRecommend = [...payload.recommend];
                payload['recommendProducts'] = _.chain(tmpRecommend).filter((o) => o.category === "product" && o._id).map((v) => v._id).value();
                payload['recommendPlans'] = _.chain(tmpRecommend).filter((o) => o.category === "plan" && o._id).map((v) => v._id).value();
                delete payload.recommend
            } else if (key === "flipCardIconImage" && item?.fileKey) {
                payload['flipCardIconImage'] = item.fileKey
            } else if (key === "cardFront" || key === "cardBack" || key === "planDetailsInclude") {
                let tmpArray = [...item];
                payload[key] = _.map(tmpArray, (o) => ({ ...o, image: o?.image?.fileKey || "" }))
            } else if (key === "freeVas" || key === "paidVas" || key === "beforeBuyTncs" || key === "overallTncs" || key === "detailPageTncs") {
                payload[key] = toIds(item)
            }
        })
        payload = removeEmptyElements(payload)
        console.log("payload", payload)

        mutation.mutate(payload);
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Mobile > Add New`}</Typography>
            </>
        )
    }

    const renderForm = () => {
        switch (step) {
            case 0: {
                return (
                    <Content>
                        <Form fields={Step1Fields({ control, errors: errors.step1, name: "step1", companies })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 1: {
                return (
                    <Content>
                        <Form fields={Step2Fields({ control, errors: errors.step2, name: "step2" })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 2: {
                return (
                    <Content>
                        <Form fields={TncFields({ control, errors: errors.step3, name: "step3", tncList })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 3: {
                return (
                    <Content>
                        <Form fields={VasFields({ control, errors: errors.step4, name: "step4", vasList })} p={`14px 0px`} />
                    </Content>
                )
            }

            case 4: {
                return (
                    <Content>
                        <Form fields={RecommendFields({ control, errors, name: "step5" })} p={`14px 0px`} />
                    </Content>
                )
            }

            // case 5: {
            //     return (
            //         <Content>
            //             <ControlComponent control={control} reset={reset} getValues={getValues} />
            //         </Content>
            //     )
            // }

            default: {
                return null
            }
        }
    }

    const isLastStep = step === (Tabs.length - 1)

    const updateStep = (type) => {
        if (showSummary && type === "deduct") {
            if (type === "deduct") setShowSummary(false)
            return
        }
        if (type === 'add' && canModify) {
            if (isLastStep) {
                // handleSubmit(handleOnSubmit)()
                setShowSummary(true)
            } else {
                if (step < (Tabs.length - 1)) setStep(step + 1)
            }
        } else {
            if (step >= 1) setStep(step - 1)
        }
    }

    const onSelectPublishType = (type) => {
        if (type.id === 'publish-now') {
            setValue('status', 'active', { shouldDirty: true })
            setValue('publishStartDate', new Date().toISOString(), { shouldDirty: true })
            validateForm()
        } else {
            setPublishDialog({ open: true })
        }
    }

    const CardHeader = () => {
        return (
            <RowDirectionContainer>
                <SaveButton variant='outlined' disabled={!step} onClick={() => updateStep('deduct')}>Previous</SaveButton>
                <SaveButton
                    variant='outlined'
                    disabled={!canModify}
                    onClick={() => {
                        handleSubmit(handleOnSubmit)()
                    }}
                >
                    Save As Draft
                </SaveButton>
                {
                    showSummary ?
                        <PositionedMenu
                            label="Publish"
                            options={[
                                {
                                    label: "Publish Now",
                                    id: "publish-now"
                                },
                                {
                                    label: "Select Publish Period",
                                    id: 'select-publish-period'
                                }
                            ]}
                            onSelect={onSelectPublishType}
                            style={{ marginRight: 8 }}
                        /> :
                        <SaveButton onClick={() => updateStep('add')}>{isLastStep ? 'Preview' : 'Next'}</SaveButton>
                }
            </RowDirectionContainer>
        )
    }

    console.log("errors", errors)

    return (
        <PageWrapper
            loading={planId && isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            {
                showSummary ?
                    <Summary control={control} reset={reset} getValues={getValues} />
                    :
                    <>
                        <Progressbar step={step} data={Tabs} error={Object.keys(errors)} />
                        <div style={{ height: '100%', overflow: 'auto' }}>
                            {renderForm()}
                        </div>

                    </>
            }
            <PublishPeriodDialog
                open={publishDialog.open}
                handleClose={() => setPublishDialog({ open: false })}
                onSubmit={() => validateForm()}
                control={control}
            />
        </PageWrapper>
    )
}

export default AddMobilePlan