import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash'
import {
    PageWrapper,
    Typography,
    Button,
    Tab
} from '../../components/share';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { userContext } from '../../context/UserProvider';
import withTabSearchParams from '../../hoc/withTabSearchParams'
 import DeliveryTab from './delivery'
 import DocTab from './doc'
 import TncTab from './tnc'
 import { getPermission } from '../../utils'

const Content = styled.div`
    margin: 20px;
    height: 100%;
    overflow: auto;
`

const TABS = [
    {
        name: 'Delivery',
        id: "delivery",
    },
    // {
    //     name: 'Document Upload',
    //     id: "document-upload",
    // },
    {
        name: 'T&C',
        id: "tnc",
    }
]

function GlobalSettings({ updateTab, tabIndex }) {

    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);

    const permission = getPermission(user?.permissions?.globalSettings)

    const deliveryRef = useRef({});
    const docRef = useRef({});
    const tncRef = useRef({});

    const onClickTab = (index) => {
        updateTab(TABS[index], index)
    }

    const handleOnSubmit = () => {
        // console.log("deliveryRef", deliveryRef.current)
        if (tabIndex === 0) {
            deliveryRef.current.onSubmit()
        } else if (tabIndex === 1) {
            tncRef.current.onSubmit()
        }
    }

    const renderContent = () => {
        switch (tabIndex) {
            case 0: {
                return <DeliveryTab ref={deliveryRef} setSnackbar={setSnackbar} permission={permission} />
            }

            // case 1: {
            //     return <DocTab ref={docRef} setSnackbar={setSnackbar} permission={permission} />
            // }

            case 1: {
                return <TncTab ref={tncRef} setSnackbar={setSnackbar} permission={permission} />
            }
        }
    }


    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Global Settings`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    return (
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                margin: `0px 20px 14px 20px`,
                            }}
                            onClick={handleOnSubmit}
                            disabled={!permission.canModify}
                        >
                            Save
                        </Button>
                    )
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >
            <Content>
                {renderContent && renderContent()}
            </Content>
        </PageWrapper>
    )
}

export default withTabSearchParams(GlobalSettings, TABS)