import { Controller } from "react-hook-form";
import TextField from '../../components/share/TextField';
import Image from '../../components/share/ImageV2';
import Typography from '../../components/share/Typography';
import InputAdornment from '@mui/material/InputAdornment'
import { ReactComponent as UploadIcon } from '../../assets/upload-background.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import Button from '../../components/share/Button'
import DynamicForm from '../../components/share/form/DynamicForm';
import Counter from '../../components/share/Counter'
import Grid from '@mui/material/Grid';
import ComponentContainer from '../../components/share/ComponentContainer'
import Select from '../../components/share/Select'
import AutoCompleteWithList from "../../components/share/AutocompleteWithList";
import HtmlEditor from '../../components/share/HtmlEditor';
import RecommendProduct from "../../components/RecommendProduct";
import DatePicker from '../../components/share/DatePicker';
import { DefPlans } from './add'

const Fields = ({ control, errors = {}, readOnly }) => [
    {
        id: "select-plan",
        component: (
            <Controller
                name={`plan`}
                control={control}
                render={({ field }) => (
                    <Select
                        title={`Select Plan`}
                        direction="vertical"
                        fullWidth
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        api='/plan'
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled={readOnly}
                        error={errors?.plan}
                        helperText={errors?.plan?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "disacounted-price",
        component: (
            <Controller
                name={`discountedPrice`}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        title="Discounted Price"
                        direction="vertical"
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment style={{ marginTop: 0 }} position="start">HKD</InputAdornment>,
                        }}
                        readOnly={readOnly}
                        error={errors?.discountedPrice}
                        helperText={errors?.discountedPrice?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
    {
        id: "start-date-start-time",
        component: (
            <Grid item container direction='row' spacing={1}>
                <Grid item xs>
                    <Controller
                        name={`startDate`}
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                title="Start Date"
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.startDate}
                                helperText={errors?.startDate?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs>
                    <Controller
                        name={`startTime`}
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                title="Start Time"
                                type='time'
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.startTime}
                                helperText={errors?.startTime?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        )
    }, { id: "spacing" },
    {
        id: "end-date-end-time",
        component: (
            <Grid item container direction='row' spacing={1}>
                <Grid item xs>
                    <Controller
                        name={`endDate`}
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                title="End Date"
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.endDate}
                                helperText={errors?.endDate?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs>
                    <Controller
                        name={`endTime`}
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                title="End Time"
                                type='time'
                                direction="vertical"
                                fullWidth
                                readOnly={readOnly}
                                error={errors?.endTime}
                                helperText={errors?.endTime?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        )
    }, { id: "spacing" },
    {
        id: "select-tnc",
        component: (
            <Controller
                name={`tncs`}
                control={control}
                render={({ field }) => (
                    <Select
                        title={`Select T&C`}
                        direction="vertical"
                        fullWidth
                        textFieldProps={{
                            placeholder: "Select..."
                        }}
                        api='/tnc'
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value)
                        }}
                        disabled={readOnly}
                        error={errors?.product}
                        helperText={errors?.product?.message}
                    />
                )}
            />
        )
    }, { id: "spacing" },
]

export { Fields }