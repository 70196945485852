import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress'
import { Grid, Divider, IconButton } from '@mui/material'
import Image from '../ImageV2';
import { ReactComponent as UploadIcon } from '../../../assets/upload-background.svg';
import { ReactComponent as CloseIcon } from '../../../assets/Close.svg';
import Select from '../Select';
import Button from '../Button'
import UploadComponent from '../UploadComponent';

export default function AddImageDialog({ open, handleClose, onChange }) {

    const [img, setImg] = React.useState({})

    React.useEffect(() => {
        if (!open) setImg({})
    }, [open])

    const onImageUploaded = (img) => {
        setImg(img)
    }

    const onCloseDialog = () => {
        onChange(img?.fileKey ? img : null)
        handleClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <DialogTitle id="alert-dialog-title">
                    Add Image
                </DialogTitle>
                <IconButton style={{ marginRight: 20, height: 40, width: 40 }} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Divider sx={{ borderColor: 'mainColor.light', borderWidth: 2, margin: `0px 20px` }} />
            <DialogContent>
                <UploadComponent
                    renderComponent={({ isLoading, selectFile }) => {
                        return (
                            <Image
                                height={165}
                                width={466}
                                Icon={img?.previewUrl ? null : UploadIcon}
                                url={img?.previewUrl}
                                title="PNG, JPG and GIF files are allowed"
                                direction='vertical'
                                isLoading={isLoading}
                                onRemove={() => setImg({})}
                                onClick={selectFile}
                            />
                        )
                    }}
                    dragable
                    onChange={(e, response) => {
                        onImageUploaded(response)
                    }}
                />

                {/* <Select title="Select Category" direction='vertical' fullWidth /> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog}>Add</Button>
            </DialogActions>
        </Dialog>
    );
}
