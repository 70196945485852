import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import Table, { UnderlineText, DeleteActionIcon } from '../../components/share/Table';
import {
    Button,
    PageWrapper,
    Searchbar,
    StatusButton,
    Typography,
} from '../../components/share';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import AlertDialogProvider, { alertDialogContext } from '../../context/AlertDialogProvider';
import { vasContext } from '../../context/VasProvider';
import { deletePromoCode, updatePromoCode } from '../../apis/promoCode';
import constants from '../../constants'
import dayjs from 'dayjs'
import { getPermission } from '../../utils'
import { userContext } from '../../context/UserProvider';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';

const margin = 10

function PromoCodePage(props) {
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    
    const [user, setUser] = useContext(userContext);
    const { canView, canModify } = getPermission(user?.permissions?.promoCode)

    const [filter, setFilter] = useState({
        keyword: ""
    })
    const [selectMode, setSelectMode] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();

    const queryClient = useQueryClient()

    const deleteMutation = useMutation({
        mutationFn: (id) => deletePromoCode(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/promo'] })
        },
        onError: (error) => {
            console.log("error", error)
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        },
    })

    const statusMutation = useMutation({
        mutationFn: ({ id, payload }) => updatePromoCode(id, payload),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/promo'] })
        },
        onError: (error) => {
            console.log("error", error)
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        },
    })

    const onDeletePromoCode = (id) => {
        setDialog({
            open: true,
            title: "Are you sure to delete this promotion code?",
            handleOnClickOK: () => deleteMutation.mutate(id)
        })
    }

    const columns = useMemo(() => {
        return [
            { field: 'name', headerName: 'Campaign Name', width: 200, valueGetter: (params) => params.value?.en || "" },
            { field: 'code', headerName: 'Promo Code', width: 200, },
            { field: 'discountAmount', headerName: 'Value', width: 200, valueGetter: (params) => `HK$ ${params.value}` },
            { field: 'minSpend', headerName: 'Minimum Spending', width: 200, valueGetter: (params) => `HK$ ${params.value}` },
            { field: 'createdAt', headerName: 'Create Date', width: 150, valueGetter: (params) => dayjs(params.value).format(constants.DATE_FORMAT) },
            // { field: 'lastModifyDate', headerName: 'Last Modify Date', width: 200, sortable: false, valueGetter: (params) => dayjs(params.value).format(constants.DATE_FORMAT) },
            {
                field: 'status',
                headerName: 'Status',
                width: 130,
                type: 'actions',
                sortable: false,
                renderCell: (params) => {
                    const { id, api, value, row } = params

                    const onChange = (newStatusValue) => {
                        statusMutation.mutate(
                            {
                                id,
                                payload: { status: newStatusValue }
                            },
                            {
                                onSuccess: () => {
                                    api.updateRows([{ id: id, ...row, status: newStatusValue }])
                                },
                                onError: () => {
                                    api.updateRows([{ id: id, ...row }])
                                }
                            }
                        )
                    }

                    return (
                        <StatusButton
                            value={value}
                            onChange={onChange}
                            readOnly={!canModify}
                            options={[
                                {
                                    name: "Draft",
                                    id: "draft",
                                    color: "#4BA28D",
                                    Icon: (<EditNoteIcon />)
                                },
                                // {
                                //     name: "Pending",
                                //     id: "pending",
                                //     color: "#5D4986",
                                //     Icon: (<CheckCircleOutlineIcon />)
                                // },
                                {
                                    name: "Active",
                                    id: "active",
                                    // id: "published",
                                    color: "#306097",
                                    canEdit: true,
                                    Icon: (<CheckCircleOutlineIcon />)
                                },
                                {
                                    name: "Suspended",
                                    id: "suspended",
                                    color: "#F6F6F6",
                                    textColor: "#AEAEAE",
                                    canSelect: true,
                                    Icon: (<RemoveCircleOutlineIcon />)
                                },
                                // {
                                //     name: "Expired",
                                //     id: "expired",
                                //     color: "#8D9A66",
                                //     Icon: (<CheckCircleOutlineIcon />)
                                // },
                            ]}
                        />
                    )
                }
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: 'actions',
                width: 140,
                headerAlign: 'flex-start',
                getActions: (params) => {
                    return [
                        <UnderlineText disabled={!canView} onClick={() => navigate(`/promotion-code/${params.row._id}`)}>View</UnderlineText>,
                        <UnderlineText disabled={!canModify} onClick={() => navigate(`/promotion-code/add?promoId=${params.row._id}`)}>Clone</UnderlineText>,
                        <DeleteActionIcon disabled={!canModify} onClick={() => onDeletePromoCode(params.row._id)} />,
                    ]
                }
            }
        ]
    }, [])

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        // TODO: filter button
        
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`Promotion Code`}</Typography>
                {/* <Button color="orange" style={{ marginRight: margin }}>Export</Button>
                <UploadComponent
                    renderComponent={({ selectFile }) => (
                        <Button
                            color="blue"
                            style={{ marginRight: margin }}
                            onClick={selectFile}
                        >
                            Import
                        </Button>
                    )}
                /> */}
                {/* <Button color="green" style={{ marginRight: margin }}>Filter</Button> */}
            </>
        )
    }

    const CardHeader = () => {
        return (
            <>
                <Button
                    style={{
                        alignSelf: 'flex-end',
                        margin: `10px 20px 14px 20px`
                    }}
                    onClick={() => navigate(`/promotion-code/add`)}
                    type="add"
                >
                    Add New
                </Button>
            </>
        )
    }


    return (
        <PageWrapper
            renderHeader={Header}
            renderCardHeader={CardHeader}
        >
            <Grid container direction='row' alignItems='center' style={{ paddingRight: 10 }}>
                <Searchbar value={filter.keyword} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                columns={columns}
                api={"/promo"}
                apiParams={{
                    ...filter
                }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
                // onRowClick={(params) => {
                //     navigate(`/terms-and-conditions/${params.id}`)
                // }}
            />
        </PageWrapper>
    )
}

export default PromoCodePage