import { useState, useContext, useCallback, useMemo, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Form } from '../../components/share';
import {
    TncFields
} from './formFields';
import { request } from '../../apis/index'
import constants from '../../constants';
import {
    updateTncConfig
} from '../../apis/globalSettings'
import { getDirtyValues, toIds } from '../../utils'
import { CircularProgress, Box } from '@mui/material';
import { tncContext } from '../../context/TncProvider';

const getResetData = ({ data }) => {
    return {
        prePaid: _.find(data, { keyPath: "tnc.prePaid" })?.value?._id,
        postPaid: _.find(data, { keyPath: "tnc.postPaid" })?.value?._id,
        savePersonalInformation: _.find(data, { keyPath: "tnc.savePersonalInformation" })?.value?._id,
        directSaleThisServices: _.find(data, { keyPath: "tnc.directSaleThisServices" })?.value?._id,
        directSaleMyClubHKT: _.find(data, { keyPath: "tnc.directSaleMyClubHKT" })?.value?._id,
        freeRemovalServices: _.find(data, { keyPath: "tnc.freeRemovalServices" })?.value?._id,
    }
}

const TncSystemConfiguration = forwardRef(({ setSnackbar, permission }, ref) => {

    const [tncList] = useContext(tncContext);

    const { data, error: apiError, isError, isLoading } = useQuery({
        queryKey: ['tnc'],
        queryFn: () => request({
            method: 'get',
            url: `${constants.API_ENDPOINT}/systemConfig/tnc`
        }),
        retry: false,
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error?.result?.errorMessage?.en}`
            })
        },
    })

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, getValues, setValue } = useForm({
        // resolver: joiResolver(schema),
    });

    useEffect(() => {
        if (data?.data) {
            reset(getResetData({ data: data?.data }))
        }
    }, [data])

    const tncMutation = useMutation({
        mutationFn: (payload) => updateTncConfig(payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
            reset(getResetData({ data: data }))
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        if (_.isEmpty(dirtyFields)) {
            return setSnackbar({
                type: "success",
                message: "Success"
            })
        }
        const dirty = getDirtyValues(dirtyFields, formValue);
        tncMutation.mutate(dirty)
    }

    useImperativeHandle(ref, () => ({
        onSubmit: () => handleSubmit(handleOnSubmit)()
    }));

    if (isLoading) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="100％">
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Form fields={TncFields({ control, errors: errors, readOnly: permission.canModify ? false : true, tncList })} p={`14px 0px`} />
    )
})

export default TncSystemConfiguration