import { Box, Grid, TableCell, TableRow, Breadcrumbs, Link } from '@mui/material';
import dayjs from 'dayjs';
import Joi from 'joi';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm, } from "react-hook-form";
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import {
    Button,
    DataTable,
    PageWrapper,
    Tab2,
    TableStatusFilter,
    TextField,
    Typography,
    Form
} from '../../../components/share';
import constants from '../../../constants';
import { snackbarContext } from '../../../context/SnackbarProvider';
import { userContext } from "../../../context/UserProvider";
import { ClientInfoFields, PlanInfoFields, ExtraInfoFields } from './formFields';
import {
    // STATUS,
    activityLogSchema,
    generalActivityLogColumns,
    DefRemark
} from './index';
import { updateOrder, addOrderItemRemark } from '../../../apis/order';
import {
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import { useTheme } from '@mui/material/styles';

const STATUS = [
    {
        _id: "pending",
        name: "Pending",
        disabled: true
    },
    {
        _id: "paymentSuccess",
        name: "Payment Success",
        disabled: true
    },
    {
        _id: "paymentFailure",
        name: "Payment Failure",
        disabled: true
    },
    {
        _id: "pendingDocument",
        name: "Pending Document",
        disabled: true
    },
    {
        _id: "documentSubmitted",
        name: "Document Submitted", //TODO: product: Submitted, mobile: Document Submitted
    },
    {
        _id: "verified",
        name: "Verified",
    },
    {
        _id: "cancelled",
        name: "Cancelled",
    },
    {
        _id: "completed",
        name: "Completed",
    },
]

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        min-width: 80px;
        margin: 10px 8px;
        height: 30px;
    }
`

export const EditButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px;
    }
`

export const Content = styled.div`
    padding: 20px;
    overflow: auto;
    height: 100%;
`

const TABS = [
    {
        label: "Plan Information",
        id: "planInformation"
    },
    {
        label: "Client Information",
        id: "clientInformation",
        canEdit: true
    },
    {
        label: "Documents Upload",
        id: "documentUpload"
    },
    {
        label: "Activity Log",
        id: "activity-log"
    },
    {
        label: "Extra Info",
        id: "extra-info",
        canEdit: true
    }
]

const schema = Joi.object({
    name: {
        en: Joi.string().empty(""),
        zh: Joi.string().empty("")
    },
    email: Joi.string(),
    role: Joi.any(),
});

const DocUploadedColumns = (control) => [
    {
        dataKey: 'doc.displayName.en',
        label: 'Document',
        width: 300,
        renderCell: (item, index) => {
            return (
                <Box display="flex" flexDirection="column">
                    <Typography color="primary.dark">{item?.doc?.displayName?.en}</Typography>
                    {/* <Button variant="underline" color="neon">Preview</Button> */}
                </Box>
            )
        }
    },
    {
        dataKey: 'action',
        label: 'Action',
        renderCell: (item, index) => {
            return (
                <Box display="flex" flexDirection="row">
                    <Button disabled sx={{ height: 35 }}>Approve</Button>
                    <div style={{ height: 1, width: 20 }} />
                    <Button disabled variant="outlined" sx={{ height: 35 }}>Reject</Button>
                </Box>
            )
        }
    }
]

function MobileDetail({ data, permission, isLoading }) {

    const navigate = useNavigate();
    const theme = useTheme();

    const [tab, setTab] = useState(0);
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);

    let { orderItemId } = useParams();

    const queryClient = useQueryClient()

    const { canView, canModify } = permission

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset, getValues } = useForm({
        // resolver: joiResolver(schema),
    });

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: `${TABS[3].id}.remarks`,
    });

    useEffect(() => {
        if (data) {
            reset({
                [TABS[0].id]: {
                    transactionNumber: data?.transactionNumber,
                    orderNumber: data?.orderNumber,
                    plan: {
                        ...data?.plan,
                        efectiveDateForDisplay: `${data?.plan?.newNumberEffectiveDate} ${data?.plan?.newNumberEffectiveTime}`
                    },
                    originalPrice: data?.originalPrice,
                    discountedPrice: data?.discountedPrice,
                    status: data?.status,
                    freeVas: _.map(data?.freeVas, (item) => {
                        return item._id
                    }),
                    paidVas: _.map(data?.paidVas, (item) => {
                        return item._id
                    }),
                    detailTncs: data?.acceptedTncs?.privacyStatementTncs,
                    overallTncs: data?.acceptedTncs?.overallTncs,
                },
                [TABS[1].id]: {
                    title: data?.userInfo?.title,
                    fullName: data?.userInfo?.fullName,
                    idType: data?.userInfo?.idType,
                    idNumber: data?.userInfo?.idNumber,
                    //idApproved
                    idNumber: data?.userInfo?.idNumber,
                    companyName: data?.userInfo?.companyName,
                    companyPosition: data?.userInfo?.companyPosition,
                    email: data?.userInfo?.email,
                    mobileNumber: data?.userInfo?.mobileNumber,
                    address1: data?.userInfo?.mailingAddress?.[0],
                    address2: data?.userInfo?.mailingAddress?.[1],
                    mailingDistrict: data?.userInfo?.mailingDistrict,
                    // addressProof
                    contactUserInfo: {
                        title: data?.contactUserInfo?.title,
                        fullName: data?.contactUserInfo?.fullName,
                        email: data?.contactUserInfo?.email,
                        mobileNumber: data?.contactUserInfo?.mobileNumber,
                        idType: data?.contactUserInfo?.idType,
                        idNumber: data?.contactUserInfo?.idNumber,
                    },
                    deliveryInfo: data?.deliveryInfo
                },
                [TABS[3].id]: {
                    remarks: data?.remarks
                },
                [TABS[4].id]: {
                    ...data?.extraInfo
                }

            })
        }
    }, [data])

    const mutation = useMutation({
        mutationFn: (payload) => updateOrder(orderItemId, payload),
        onSuccess: (data) => {
            // queryClient.invalidateQueries({ queryKey: ["planDetail", { planId }] })
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        let payload = {};
        _.forEach(dirtyFields, (value, key) => {
            payload[key] = formValue[key]
        })
        mutation.mutate({ extraInfo: payload['extra-info'] })
    }

    const activityLogMutation = useMutation({
        mutationFn: (payload) => addOrderItemRemark(orderItemId, payload),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["orderDetail", { orderItemId }] })
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmitActivityLog = (index) => {
        try {
            let tmp = getValues(`${TABS[3].id}.remarks`)?.[index];
            const payload = {
                item: tmp?.item,
                reason: tmp?.reason,
                remark: tmp?.remark
            }
            const result = activityLogSchema.validate(payload);
            if (result.error) {
                const displayErr = _.map(result?.error.details, (error) => error.message).join(" , ")
                setSnackbar({
                    type: 'error',
                    message: displayErr
                })
            } else {
                activityLogMutation.mutate(payload)
            }
        } catch (error) {
            console.log("handleOnSubmitRemarks-error", error)
        }
    }

    const ActivityLogColumns = () => {
        return [
            ...generalActivityLogColumns(control, `${TABS[3].id}.remarks`),
            {
                dataKey: 'remove',
                label: 'Action',
                width: 100,
                style: {
                    justifyContent: 'center',
                },
                renderCell: (item, index) => {
                    if (!item.canRemove) return null
                    return (
                        <Grid container direction='row'>
                            <Grid item xs>
                                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => remove(index)} />
                            </Grid>
                            <Grid item xs>
                                <Typography 
                                    variant={"underline"} 
                                    onClick={() => handleOnSubmitActivityLog(index)}
                                    style={{
                                        "pointer-events": canModify? undefined : "none"
                                    }}
                                    disabled={!canModify}
                                >
                                    Add
                                </Typography>
                            </Grid>
                        </Grid>

                    )
                }
            }
        ]
    }

    const Header = () => {
        return (
            <div style={{ flex: 1 }}>
                <Breadcrumbs aria-label="breadcrumb" separator=">">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/order?type=orders">
                        <Typography variant="main" bold>All Orders</Typography>
                    </Link>
                    <Link underline="hover" color="inherit" component={RouterLink} to={`/order?type=orderItems&orderId=${data?.orderId}&orderNumber=${data?.orderNumber}`}>
                        <Typography bold variant="main">{data?.orderNumber}</Typography>
                    </Link>
                    <Typography bold variant="main">{data?.subOrderNumber}</Typography>
                </Breadcrumbs>
            </div>
        )
    }

    const renderForm = () => {
        const currentTab = TABS[tab].id
        switch (currentTab) {
            case TABS[0].id: {
                return (
                    <Form
                        fields={PlanInfoFields({
                            name: TABS[0].id,
                            control,
                            errors: errors[TABS[0].id],
                            readOnly: TABS[0]?.canEdit ? false : true
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case TABS[1].id: {
                return (
                    <Form
                        fields={ClientInfoFields({
                            name: TABS[1].id,
                            control,
                            errors: errors[TABS[1].id],
                            readOnly: TABS[1]?.canEdit ? false : true
                        })}
                        p={`14px 0px`}
                    />
                )
            }

            case TABS[2].id: {
                return (
                    <Grid container direction='row'>
                        <DataTable
                            data={data?.documents}
                            columns={DocUploadedColumns(control)}
                            readOnly={TABS[2]?.canEdit ? false : true}
                        />
                    </Grid>
                )
            }

            case TABS[3].id: {
                return (
                    <DataTable
                        data={fields}
                        columns={ActivityLogColumns()}
                        renderCustomListItem={() => {
                            const onClickAdd = () => append({ ...DefRemark, updatedBy: user, _id: _.uniqueId('remark-'), canRemove: true })
                            return (
                                <TableRow>
                                    <TableCell sx={{ border: 'none' }}>
                                        <Typography
                                            variant={"underline"}
                                            style={{ 
                                                display: 'inline-block',
                                                "pointer-events": canModify? undefined : "none"
                                            }}
                                            onClick={onClickAdd}
                                            disabled={!canModify}
                                        >
                                            {"+ Add remarks"}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        }}
                    />
                )
            }

            case TABS[4].id: {
                return (
                    <Form
                        fields={ExtraInfoFields({
                            name: TABS[4].id,
                            control,
                            errors: errors[TABS[4].id],
                            readOnly: TABS[4]?.canEdit ? false : true
                        })}
                        p={`14px 0px`}

                    />
                )
            }
        }
    }

    return (
        <PageWrapper
            loading={isLoading || activityLogMutation.isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
        >
            <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
                <TableStatusFilter value={data?.status} options={STATUS} style={{ margin: `0px 10px` }} titleStyle={{ color: theme.palette.primary.main }} disabled />
                {/* <Typography variant="header3" sx={{ mr: 1 }}>Edited on {dayjs().format(constants.DATE_TIME_FORMAT)}</Typography> */}
                {/* <EditButton onClick={() => handleSubmit(handleOnSubmit)()}>Edit</EditButton> */}
                {TABS[tab]?.canEdit && <SaveButton disabled={!canModify} onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>}
            </Grid>
            <Tab2
                tabs={TABS}
                selected={TABS[tab].id}
                onChange={(id, index) => setTab(id, index)}
            />
            <Content>
                {renderForm()}
            </Content>
        </PageWrapper>
    )
}

export default MobileDetail