import { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { Grid, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { Routes, Route, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash'
import Typography from '../../components/share/Typography';
import Button from '../../components/share/Button';
import PageWrapper from '../../components/share/PageWrapper';
import { listMedia } from '../../apis/medias';
import UploadImgItem from '../../components/UploadImgItem';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { fetchMedia } from '../../apis/medias';

const AddButton = styled(Typography)`
    && {
        text-decoration-line: underline;
        cursor: pointer;
        padding-left: 30px;
        padding-bottom: 20px;
    }
`

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

const schema = Joi.object({
    images: Joi.array().items(
        Joi.object({
            image: Joi.any().required(),
            title: Joi.string(),
            description: Joi.string(),
            category: Joi.any().required(),
        })
    )
});

function MediaDetailPage() {

    const navigate = useNavigate();

    let { mediaId } = useParams();

    const { control, register, formState: { errors }, handleSubmit, reset } = useForm({
        resolver: joiResolver(schema),
    });

    const handleOnSubmit = (formValue) => {
        console.log("formValue", formValue)
    }


    const Header = () => {
        return (
            <Typography bold style={{ flex: 1 }}>{`Media > ${mediaId}`}</Typography>
        )
    }

    const CardHeader = () => {
        return (
            <SaveButton onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>
        )
    }

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["mediaDetail", { mediaId }],
        queryFn: async() => await fetchMedia(mediaId)
    });

    console.log("data", data)

    return (
        <PageWrapper
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Grid container direction='column' style={{ height: '100%', overflow: 'auto' }}>
                <UploadImgItem
                    control={control}
                    data={data}
                    reset={reset}
                />
            </Grid>

        </PageWrapper>
    )
}

export default MediaDetailPage