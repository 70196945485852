import * as React from 'react';
import Button from '../share/Button';
import ProductSliderGallery from './ProductSliderGallery';


export default function AddImageDialogWithButton(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                variant="outlined"
                size="small"
                onClick={handleClickOpen}
            >
                {props.readOnly ? "View" : "+ Add Image"}
            </Button>
            <ProductSliderGallery
                open={open}
                onClose={handleClose}
                {...props}
            />
        </div>
    );
}
