import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient
} from '@tanstack/react-query'
import _ from 'lodash'
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import {
    PageWrapper,
    Typography,
    Button,
    Form
} from '../../components/share';
import SnackbarProvider, { snackbarContext } from '../../context/SnackbarProvider';
import { tncContext } from '../../context/TncProvider';
import { fetchVas, updateVas } from '../../apis/vas';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { FIELDS } from './formFields';
import { getDirtyValues, getPermission } from '../../utils'
import { schema } from './add'
import { userContext } from '../../context/UserProvider';
import constants from '../../constants';

const SaveButton = styled(Button)`
    && {
        align-self: end; 
        width: 80px;
        margin: 10px 20px 14px 20px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 100%;
    overflow: auto;
`

const getResetData = ({ data }) => {
    return {
        promotionTitle: {
            en: data?.promotionTitle?.en,
            zh: data?.promotionTitle?.zh,
        },
        name: {
            en: data?.name?.en,
            zh: data?.name?.zh,
        },
        newImageKey: data?.imageUrl ? {
            fileKey: data?.image,
            previewUrl: data?.imageUrl
        } : null,
        discountedPrice: data?.discountedPrice,
        originalPrice: data?.originalPrice,
        detail: {
            en: data?.detail?.en,
            zh: data?.detail?.zh,
        },
        tncs: data?.tncs,
        vasType: data?.vasType,
        vasId: data?.vasId,
    }
}

function VasDetail(props) {

    let { vasId } = useParams();
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [user, setUser] = useContext(userContext);
    const [tncList] = useContext(tncContext);

    const { canView, canModify } = getPermission(user?.permissions?.vas)

    const { control, handleSubmit, watch, formState: { errors, dirtyFields }, reset } = useForm({
        resolver: joiResolver(schema),
    });

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ["vasDetail", { vasId }],
        queryFn: async () => await fetchVas(vasId),
    });

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setSnackbar({
                type: "error",
                message: constants.INVALID_INPUT_ERR_MSG
            })
        }
    }, [errors])

    useEffect(() => {
        if (!isLoading && data) {
            reset(getResetData({ data }))
        }
    }, [data])

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (payload) => updateVas(vasId, payload),
        onSuccess: (data) => {
            setSnackbar({
                type: 'success',
                message: 'Success'
            });
            reset(getResetData({ data }))
            queryClient.invalidateQueries({ queryKey: ['allVas'] })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const handleOnSubmit = (formValue) => {
        const dirty = getDirtyValues(dirtyFields, formValue)
        let payload = { ...dirty }
        if (payload.hasOwnProperty('newImageKey')) {
            payload['newImageKey'] = payload?.newImageKey?.fileKey || ""
        }
        // payload = removeEmptyElements(payload)
        console.log("payload", payload)
        mutation.mutate(payload)
    }

    console.log("errors", errors)

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }}>{`VAS > Mobile > ${vasId}`}</Typography>
            </>
        )
    }

    const CardHeader = () => {
        return (
            <SaveButton disabled={!canModify} onClick={() => handleSubmit(handleOnSubmit)()}>Save</SaveButton>
        )
    }

    return (
        <PageWrapper
            loading={isLoading || mutation.isLoading}
            renderHeader={Header}
            renderBackButton={() => <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} />}
            renderCardHeader={CardHeader}
        >
            <Content>
                <Typography variant="header3">Fields marked * are required</Typography>
                <Form fields={FIELDS({ control, errors, tncList, readOnly: !canModify })} p={`14px 0px`} />
            </Content>
        </PageWrapper>
    )
}

export default VasDetail