import { useState, useContext, useMemo, useRef } from 'react';
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';
import { snackbarContext } from '../../context/SnackbarProvider';
import { alertDialogContext } from '../../context/AlertDialogProvider';
import { userContext } from '../../context/UserProvider';
import withTabSearchParams from '../../hoc/withTabSearchParams'
import {
    Typography,
    Button,
    Tab,
    PageWrapper,
    TableStatusFilter,
    Searchbar,
    StatusButton,
    UploadComponent,
    FilterButton
} from '../../components/share'
import Table, { UnderlineText, DeleteActionIcon } from '../../components/share/Table';
import constants from '../../constants';
import { deletePlan, updatePlan, deleteNumber } from '../../apis/plan';
import { getPermission } from '../../utils'


const margin = 10

const TABS = [
    {
        name: 'Mobile Service Plans',
        id: 'mobile-service-plans'
    },
    {
        name: 'Number Pool',
        id: "number-pool"
    }
]

const NUMBER_COL_STATUS = [
    {   
        name: "All",
        _id: undefined,
    },
    {   
        name: "Available",
        _id: 'available'
    },
    {   
        name: "On Hold",
        _id: 'onHold'
    }
]

const STATUS = [
    {
        name: "All",
        _id: null
    },
    {
        name: "Active",
        _id: "active",
    },
    {
        name: "Suspended",
        _id: "suspended",
    },
    {
        name: "Expired",
        _id: "expired",
    },
    {
        name: "Draft",
        _id: "draft",
    },
]

const MobileServicePlanColumns = ({ onDelete, navigateToPlanDetail, navigateToClonePlan, mutation, canModify, canView }) => [
    { field: 'planId', headerName: 'PlanID', width: 100, sortable: false, },
    { field: 'planName.en', headerName: 'Plan Name(English)', width: 160, valueGetter: (params) => params.row?.planName?.en },
    { field: 'planName.zh', headerName: 'Plan Name(Chinese)', width: 160, valueGetter: (params) => params.row?.planName?.zh },
    { field: 'company.name', headerName: 'Company Name', width: 160, valueGetter: (params) => params.row?.company?.name },
    { field: 'publishStartDate', headerName: 'Start Date & Time', width: 160, valueGetter: (params) => params.value ? dayjs(params.value).format(constants.DATE_TIME_FORMAT) : "" },
    { field: 'publishEndDate', headerName: 'End Date & Time', width: 160, valueGetter: (params) => params.value ? dayjs(params.value).format(constants.DATE_TIME_FORMAT) : "" },
    { field: 'originalPrice', headerName: 'Original Price', width: 140, },
    { field: 'discountedPrice', headerName: 'Discounted Price', width: 140 },
    {
        field: 'status',
        headerName: 'Status',
        width: 130,
        sortable: false,
        type: 'actions',
        renderCell: (params) => {
            const { id, api, value, row } = params

            const onChange = (newStatusValue) => {
                // api.updateRows([{ id: id, ...row, status: newStatusValue }])
                // onUpdateStatus && onUpdateStatus(id, newStatusValue)
                mutation.mutate(
                    {
                        id,
                        payload: { status: newStatusValue }
                    },
                    {
                        onSuccess: () => {
                            api.updateRows([{ id: id, ...row, status: newStatusValue }])
                        },
                        onError: () => {
                            api.updateRows([{ id: id, ...row }])
                        }
                    }
                )
            }

            return (
                <StatusButton
                    value={value}
                    onChange={onChange}
                    readOnly={!canModify}
                />
            )
        }
    },
    {
        field: 'actions',
        headerName: 'Action',
        type: 'actions',
        width: 140,
        headerAlign: 'flex-start',
        getActions: (params) => {
            const canDelete = params?.row?.status === 'active' ? false : true
            return [
                <UnderlineText sx={{ color: 'primary.main' }} disabled={!canView} onClick={() => navigateToPlanDetail(params.row._id)}>View</UnderlineText>,
                <UnderlineText sx={{ color: 'primary.main' }} disabled={!canModify} onClick={() => navigateToClonePlan(params.row._id)}>Clone</UnderlineText>,
                <DeleteActionIcon disabled={!canModify} hide={!canDelete} onClick={() => onDelete(params.row._id)} />
            ]
        }
    }
]

const NumberPoolColumns = ({ onDelete, canModify }) => [
    {
        field: 'number',
        headerName: 'Number',
        flex: 1
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: (params) => {
            return (
                <div>{params.value}</div>
            )
        }
    },
    {
        field: 'actions',
        headerName: 'Action',
        type: 'actions',
        width: 80,
        headerAlign: 'flex-start',
        getActions: (params) => {
            return [
                <DeleteActionIcon disabled={!canModify} onClick={() => onDelete(params.row._id)} />
            ]
        }
    }
]

function MobilePage({ updateTab, tabIndex }) {

    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [dialog, setDialog] = useContext(alertDialogContext);
    const [user, setUser] = useContext(userContext);

    const { canView, canModify } = getPermission(user?.permissions?.plan)
    const numPoolPermission = getPermission(user?.permissions?.numberPool)

    const queryClient = useQueryClient()

    const [filter, setFilter] = useState({
        keyword: "",
        status: null
    })

    const onClickTab = (index) => {
        updateTab(TABS[index], index)
        setFilter({})
    }

    const mutation = useMutation({
        mutationFn: (id) => deletePlan(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/plan'] });
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const statusMutation = useMutation({
        mutationFn: (payload) => updatePlan(payload.id, payload.payload),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["plan"] })
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const deleteNumberMutation = useMutation({
        mutationFn: (id) => deleteNumber(id),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['/numberPool'] });
            setSnackbar({
                type: 'success',
                message: 'Success'
            })
        },
        onError: (error) => {
            setSnackbar({
                type: 'error',
                message: `${error.code} ${error.errorMessage ? `- ${error.errorMessage?.en}` : ""}`
            })
        }
    })

    const hadnleOnDelete = (id) => {
        if (tabIndex === 1) {
            setDialog({
                open: true,
                title: "Are you sure to delete this number?",
                handleOnClickOK: () => deleteNumberMutation.mutate(id)
            })
        } else {
            setDialog({
                open: true,
                title: "Are you sure to delete this plan?",
                handleOnClickOK: () => mutation.mutate(id)
            })
        }
    }

    const navigateToPlanDetail = (id) => {
        navigate(`/mobile/${id}`)
    }

    const navigateToClonePlan = (id) => {
        navigate(`/mobile/add?planId=${id}`)
    }

    const columns = useMemo(() => {
        if (tabIndex === 0) return MobileServicePlanColumns({ onDelete: hadnleOnDelete, navigateToPlanDetail, navigateToClonePlan, mutation: statusMutation, canModify, canView })
        else return NumberPoolColumns({ onDelete: hadnleOnDelete, canModify: numPoolPermission.canModify })
    }, [tabIndex])

    const onApiError = (error) => {
        setSnackbar({
            type: 'error',
            message: `${error?.response?.data?.result?.errorMessage?.en || error?.message}`
        })
    }

    const Header = () => {
        return (
            <>
                <Typography bold style={{ flex: 1 }} >{`Mobile > ${TABS[tabIndex].name}`}</Typography>
                {canView && !tabIndex &&
                    <FilterButton
                        style={{ marginRight: margin }}
                        filters={[
                            {
                                type: 'date-range',
                                startDataKey: 'startDate',
                                endDataKey: 'endDate',
                            },
                            {
                                type: 'select',
                                dataKey: 'status',
                                options: STATUS
                            }
                        ]}
                        value={filter}
                        disabled={!canView}
                        onChange={(newFilterValue) => setFilter({ ...filter, ...newFilterValue })}
                    />
                }
            </>
        )
    }

    const CardHeader = () => {
        return (
            <Tab
                tabs={TABS}
                selected={tabIndex}
                onChange={onClickTab}
                renderButton={() => {
                    if (!canModify) return null
                    if (!tabIndex) {
                        return (
                            <Button
                                style={{
                                    alignSelf: 'flex-end',
                                    margin: `0px 20px 14px 20px`,
                                }}
                                type="add"
                                onClick={() => navigate(`/mobile/add`)}
                            >
                                Add New
                            </Button>
                        )
                    }
                }}
            />
        )
    }

    return (
        <PageWrapper
            renderHeader={Header}
            renderCustomCardHeader={CardHeader}
        >

            <Grid container direction='row' alignItems='center' style={{ paddingLeft: 10 }}>
                <TableStatusFilter 
                    options={tabIndex ? NUMBER_COL_STATUS : STATUS}
                    value={filter.status} 
                    disabled={!canView} 
                    onChange={(newValue) => setFilter({ ...filter, status: newValue })}     
                />
                <Searchbar value={filter.keyword} disabled={!canView} onChange={(e) => setFilter({ ...filter, keyword: e.target.value })} />
                {/* <Checkbox label="Select" fullWidth={false} checked={selectMode} onChange={(event) => setSelectMode(event.target.checked)} /> */}
                <Grid item xs />
                {tabIndex && numPoolPermission.canModify ?
                    <UploadComponent
                        renderComponent={({ selectFile }) => (
                            <Button
                                color="blue"
                                style={{ marginRight: margin }}
                                onClick={selectFile}
                            >
                                Import Number Pool
                            </Button>
                        )}
                    />
                    : null
                }
            </Grid>
            <Table
                style={{ margin: `0px 20px 20px 20px` }}
                columns={columns}
                api={tabIndex ? "/numberPool" : "/plan"}
                apiParams={{
                    ...filter
                }}
                sortingMode="server"
                onError={onApiError}
                setSnackbar={setSnackbar}
            />
        </PageWrapper>
    )

}

export default withTabSearchParams(MobilePage, TABS);