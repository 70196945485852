import { forwardRef } from 'react'
import { Grid, CircularProgress } from '@mui/material';
import Image, { ImgContainer } from '../share/ImageV2';
import UploadComponent from './UploadComponent'
import AddIcon from '@mui/icons-material/Add';
import Typography from './Typography';

function ImgGrid({ selected, data, onImageUploaded, imgSize, spacing = 2, readOnly, onRemove, style, onClickImg }) {

    return (
        <Grid container spacing={spacing} justify="flex-start" style={style}>
            {
                data.map((item, position) => {

                    if (item.id === 'upload') {
                        return (
                            <Grid
                                item
                                // xs={1}
                                key={item.id}
                                style={{ marginBottom: 10 }}
                            >
                                <UploadComponent
                                    renderComponent={({ isLoading, selectFile }) => {
                                        return (
                                            <ImgContainer
                                                height={imgSize}
                                                width={imgSize}
                                                onClick={selectFile}
                                                style={{ flexDirection: 'column', flexWrap: 'nowrap', cursor: 'pointer' }}
                                            >
                                                {
                                                    isLoading ? <CircularProgress /> :
                                                        <>
                                                            <AddIcon />
                                                            <Typography fontSize={12}>Upload Image</Typography>
                                                        </>
                                                }
                                            </ImgContainer>
                                        )
                                    }}
                                    accept="image/*"
                                    onChange={(event, response) => {
                                        onImageUploaded && onImageUploaded(response)
                                    }}
                                />
                            </Grid>
                        )
                    }

                    if (item.id === 'custom') {
                        return (
                            <Grid
                                item
                                // xs={1}
                                key={item.id}
                                style={{ marginBottom: 10 }}
                            >
                                {item?.renderComponent()}
                            </Grid>
                        )
                    }

                    const isSelected = selected?.id === item.id;
                    return (
                        <Grid item key={item.id} style={{ marginBottom: 10 }}>
                            <Image
                                height={imgSize}
                                width={imgSize}
                                url={item.url}
                                readOnly={readOnly}
                                imgStyle={{ border: `${isSelected ? 3 : 1}px solid ${isSelected ? '#607FF7' : '#AEAEAE'}`, borderRadius: 5 }}
                                onClick={() => {
                                    if (onClickImg) {
                                        onClickImg(item)
                                    } else {
                                        window.open(item.url)
                                    }
                                }}
                                onRemove={() => onRemove && onRemove({ position })}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default ImgGrid