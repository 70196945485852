import styled from "styled-components";
import { Controller } from "react-hook-form";
import TextField from '../../components/share/TextField';
import Image from '../../components/share/ImageV2';
import Typography from '../../components/share/Typography';
import InputAdornment from '@mui/material/InputAdornment'
import { ReactComponent as UploadIcon } from '../../assets/upload-background.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import Button from '../../components/share/Button'
import DynamicForm from '../../components/share/form/DynamicForm';
import Counter from '../../components/share/Counter'
import Grid from '@mui/material/Grid';
import ComponentContainer from '../../components/share/ComponentContainer'
import Select from '../../components/share/Select'
import AutoCompleteWithList from "../../components/share/AutocompleteWithList";
import HtmlEditor from '../../components/share/HtmlEditor';
import RadioGroup from '../../components/share/RadioGroup'
import HKIDInput from '../../components/share/HKIDInput'
import DatePicker from '../../components/share/DatePicker'
import { styled as styledMui } from '@mui/material/styles';

const DocProofBox = styledMui('div')(
    ({ theme }) => `
    box-sizing: border-box;
    width: 124px;
    height: 44px;
    background: #F4F4F4;
    border: 2px dashed ${theme.palette.secondary.main};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.secondary.main};
  `,
);

const Fields = ({ control, errors, readOnly }) => {
    return [
        {
            id: 'header',
            component: (
                <Typography variant="header3" sx={{ marginBottom: 1.5 }}>Fields marked * are required</Typography>
            )
        }, { id: "spacing" },
        {
            id: 'sub-header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1 }}>Contact Person Information</Typography>
            )
        }, { id: "spacing" },
        {
            id: "full-name-en",
            component: (
                <Controller
                    name={`fullName.en`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Full Name (English)*"
                            direction="vertical"
                            fullWidth
                            error={errors?.fullName?.en}
                            helperText={errors?.fullName?.en?.message}
                        />
                    )}
                />
            )
        },
        {
            id: "full-name-zh",
            component: (
                <Controller
                    name={`fullName.zh`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Full Name (Chinese)*"
                            direction="vertical"
                            fullWidth
                            error={errors?.fullName?.zh}
                            helperText={errors?.fullName?.zh?.message}
                        />
                    )}
                />
            )
        },
        {
            id: "company-name",
            component: (
                <Controller
                    name={`companyName`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Company Name*"
                            direction="vertical"
                            fullWidth
                            error={errors?.companyName}
                            helperText={errors?.companyName?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "job-position",
            component: (
                <Controller
                    name={`jobPosition`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Job Positions"
                            direction="vertical"
                            fullWidth
                            error={errors?.jobPosition}
                            helperText={errors?.jobPosition?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "certificate-type",
            component: (
                <Controller
                    name={`certificateType`}
                    control={control}
                    render={({ field }) => (
                        <RadioGroup
                            title="Cetificate Type*"
                            iconType="square"
                            options={[
                                {
                                    label: 'HKID',
                                    value: 'hkid'
                                },
                                {
                                    label: 'Passport',
                                    value: 'passport'
                                },
                            ]}
                            row
                            direction="vertical"
                            disabled={readOnly}
                            {...field}
                        // error={errors?.certificateType}
                        // helperText={errors?.certificateType?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "hkid-number",
            component: (
                <Controller
                    name={`idNumber`}
                    control={control}
                    render={({ field }) => (
                        <HKIDInput
                            {...field}
                            title="HK Identity Card No.*"
                            direction="vertical"
                            error={errors?.idNumber}
                            helperText={errors?.idNumber?.message}
                        />
                    )}
                />
            )
        },
        {
            id: "hkid-proof",
            component: (
                <Controller
                    name={`hkidProof`}
                    control={control}
                    render={({ field }) => (
                        <ComponentContainer title="HK Identity Card Proof*" direction="vertical">
                            <DocProofBox
                                {...field}
                            //onClick={() => window.open(field.value)}
                            >
                                {field?.value || "testing.jpg"}
                            </DocProofBox>
                        </ComponentContainer>
                    )}
                />
            )
        },
        {
            id: "date-of-birth",
            component: (
                <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            {...field}
                            title="date of birth*"
                            direction="vertical"
                            fullWidth
                            error={errors?.dateOfBirth}
                            helperText={errors?.dateOfBirth?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: 'sub-header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1 }}>Mailing Address</Typography>
            )
        }, { id: "spacing" },
        {
            id: "address",
            component: (
                <Controller
                    name={`address`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Address"
                            direction="vertical"
                            fullWidth
                            error={errors?.address}
                            helperText={errors?.address?.message}
                        />
                    )}
                />
            )
        },
        {
            id: "address-proof",
            component: (
                <Controller
                    name={`addressProof`}
                    control={control}
                    render={({ field }) => (
                        <ComponentContainer title="Address Proof*" direction="vertical">
                            <DocProofBox
                                {...field}
                            //onClick={() => window.open(field.value)}
                            >
                                {field?.value || "testing.jpg"}
                            </DocProofBox>
                        </ComponentContainer>
                    )}
                />
            )
        },
        {
            id: "district",
            component: (
                <Controller
                    name={`district`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="District*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={[
                                {
                                    _id: "tuen-mun",
                                    name: "Tuen Mun"
                                },
                                {
                                    _id: "shatin",
                                    name: "Shatin"
                                },
                                {
                                    _id: "mongkok",
                                    name: "Shatin"
                                }
                            ]}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            disabled={readOnly}
                            error={errors?.company}
                            helperText={errors?.company?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: 'sub-header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1 }}>Account Status</Typography>
            )
        }, { id: "spacing" },
        {
            id: "address",
            component: (
                <Controller
                    name={`email`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Email"
                            direction="vertical"
                            fullWidth
                            error={errors?.email}
                            helperText={errors?.email?.message}
                        />
                    )}
                />
            )
        },
    ]
}

export {
    Fields
} 