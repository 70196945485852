import { useEffect, useState } from 'react';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '../share/Typography';
import Grid from '@mui/material/Grid';
import ImgGrid from '../share/ImgGrid';
import Image from '../share/ImageV2';
import TextField from '../share/TextField'
import Button from '../share/Button'
import _ from 'lodash'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, color: "primary.main" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "#464646",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const upload = {
  id: 'upload',
  xs: false,
}

const imgSize = 197

export default function CustomizedDialogs({ readOnly, open, onClose, value = [], onChange, rowData, title, imgDesc }) {

  const [selected, setSelected] = useState()
  const [tmpImages, setTmpImages] = useState(value)

  const onSaveAlternativeText = () => {
    let newImgs = [...tmpImages]
    const index = _.findIndex(tmpImages, { id: selected.id })
    newImgs[index] = selected;
    setTmpImages(newImgs)
  }

  const onSave = () => {
    onChange && onChange(tmpImages)
    onClose && onClose()
  }

  const handleOnClose = () => {
    setTmpImages(value)
    onClose && onClose()
  }

  const data = readOnly ? value : [{ ...upload }, ...tmpImages]

  return (
    <div>
      <BootstrapDialog
        onClose={handleOnClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'md'}
        PaperProps={{
          sx: {
            height: 600,
          }
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleOnClose}>
          {title || "Slider Gallery"}
        </BootstrapDialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }}>
          <Grid container direction='row' columns={3} sx={{ height: '100%' }}>
            <Grid item xs direction='column' sx={{ border: `1px solid secondary.main`, borderRadius: 1, p: 3, mr: 2, height: '100%' }}>
              {imgDesc && <Typography variant="header" sx={{ mb: 1 }}>{imgDesc}</Typography>}
              <Typography variant="header" sx={{ mb: 2 }} >{rowData?.en}</Typography>
              <ImgGrid
                data={data}
                selected={selected}
                style={{
                  overflow: 'auto',
                  height: '100%'
                }}
                onImageUploaded={(response) => {
                  let cloneTmpImgs = _.cloneDeep(tmpImages)
                  cloneTmpImgs.push({
                    ...response,
                    url: response?.previewUrl,
                    xs: false,
                    alternativeText: "",
                    id: _.uniqueId()
                  })
                  setTmpImages(cloneTmpImgs)
                }}
                imgSize={imgSize}
                readOnly={readOnly}
                onRemove={({ position }) => {
                  let cloneTmpImgs = [...tmpImages]
                  cloneTmpImgs.splice((position - 1), 1)
                  setTmpImages(cloneTmpImgs)
                }}
              // onClickImg={(item) => {
              //   setSelected(item)
              // }}
              />
            </Grid>
            {/* <Grid item xs sx={{ border: `1px solid #AEAEAE`, p: 3, borderRadius: 1, }}>
              {selected?.id &&
                <>
                  <Image
                    height={imgSize}
                    width={imgSize}
                    url={selected.url}
                    readOnly={readOnly}
                    imgStyle={{ border: `1px solid #AEAEAE`, borderRadius: 5 }}
                    style={{ alignItems: 'center', marginBottom: 20 }}
                    hideRemove
                  />
                  <TextField
                    title="Alternative Text"
                    direction={"vertical"}
                    fullWidth
                    value={selected?.alternativeText || ""}
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        alternativeText: e.target.value
                      })
                    }}
                  />
                  <Box display={'flex'} justifyContent='flex-end' sx={{ mt: '20px' }}>
                    <Button onClick={onSaveAlternativeText}>Save</Button>
                  </Box>
                </>
              }
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onSave}>
            {readOnly ? "OK" : "Save"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
