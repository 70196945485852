import { useRef, useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import TextField from './TextField';
import PropTypes from 'prop-types';
import Typography from './Typography'
import { InputAdornment, Grid, Box } from "@mui/material";
import ComponentContainer from './ComponentContainer';
// import { ReactComponent as UploadIcon } from '../assets/upload-background.svg';
import { ReactComponent as RemoveIcon } from '../../assets/delete-icon.svg';
import _ from 'lodash';
import Button from './Button'
import { Editor } from '@tinymce/tinymce-react';

//<p class="red">Red text</p>
function HtmlEditor({ value, onChange, height = 500, readOnly, ...props }) {

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    return (
        <ComponentContainer direction='vertical' {...props}>
            <Editor
                apiKey='j4oese83nl0q0fkm3ax9j2ue9c2zy1av811ybpmo3eob0d28'
                value={value}
                onInit={(evt, editor) => editorRef.current = editor}
                init={{
                    height: height,
                    font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px",
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'help', 'wordcount', 'importcss'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: `
                        body { font-family:Helvetica,Arial,sans-serif; font-size:16px };
                    `,
                }}
                onEditorChange={(newValue, editor) => {
                    // setEditorValue(newValue)
                    onChange && onChange(newValue)
                }}
                disabled={readOnly}
            />

        </ComponentContainer>
    )
}

export default HtmlEditor