import { request } from './index';
import constants from '../constants';

const updateDeliveryConfig = (payload) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/systemConfig/delivery`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateDocConfig = (payload) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "put",
            url: `${constants.API_BASE_ENDPOINT}/systemConfig/docUpload`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateTncConfig = (payload) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/systemConfig/tnc`,
            data: payload
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}


export {
    updateDeliveryConfig,
    updateDocConfig,
    updateTncConfig
}
