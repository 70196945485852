import { request } from './index';
import constants from '../constants';

const listMedia = ({ queryKey }) => {
    const [_, params] = queryKey
    return new Promise((resolve, reject) => {

        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/media?category=${params.category}`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const fetchMedia = (id) => {
    return new Promise((resolve, reject) => {

        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/media/${id}`
        })
            .then(res => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

export {
    listMedia,
    fetchMedia
}