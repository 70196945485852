import { useEffect, useContext, useState, useLayoutEffect } from "react";
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material'
import { fetchToken, fetchTokenForInternal, getApiUrl, getCMSLoginUrl } from "../apis";
import { snackbarContext } from '../context/SnackbarProvider';
import { userContext } from "../context/UserProvider";
import styled from "styled-components";
import Typography from './share/Typography';
import Button from './share/Button';
import Snackbar from './share/Snackbar';
import pkceChallenge from 'pkce-challenge';
import constants from '../constants'
import { drawerItems } from "./Drawer";
import { styled as styledMui, createTheme, ThemeProvider } from '@mui/material/styles';

const Container = styled.div`
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
`

const Left = styledMui('div')(
    ({ theme }) => `
    && {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${theme.palette.mainColor.light};
    }
  `,
);

const Right = styled.div`
    flex: 1.5;
    display: flex;
    flex-direction: column;
    height: 100%;
`

const Content = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Footer = styled(Typography)`
    && {
        margin: 20px;
    }
`

function Login() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [snackbar, setSnackbar] = useContext(snackbarContext);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const error_description = searchParams.get('error_description');

    useEffect(() => {
        if (error_description) {
            setSnackbar({
                type: 'error',
                message: `${error_description} `
            })
        }
    }, [error_description])

    useEffect(() => {
        const access_token = localStorage.getItem('access_token')
        if (access_token) {
            navigate(`${drawerItems[0].path}${drawerItems?.[0]?.child?.[0]?.query || ""}`)
            //navigate(drawerItems[0].path)
            // setUser({ access_token })
        } else {
            const code = localStorage.getItem('codeChallenge')
            if (!code) {
                const codeChallenge = pkceChallenge();
                localStorage.setItem("codeChallenge", JSON.stringify(codeChallenge))
            }
        }
    }, [])

    const hasCode = searchParams.has('code')

    console.log('process.env.API_ENDPOINT ', JSON.stringify(process.env))
    useEffect(() => {
        let redirect_url = constants.IS_DEV ? "http://localhost:3000/auth/admin-login-redirect" : process.env.REACT_APP_LOGIN_CALLBACK_ENDPOINT
        const redirect_url_internal = constants.IS_DEV ? "http://localhost:3000/auth/admin-login-redirect" : "https://di60b086gtx6v.cloudfront.net/auth/admin-login-redirect"

        if (!redirect_url) {
            const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
            redirect_url = `${baseURL}/auth/admin-login-redirect`
        }

        if (hasCode) {
            const code = searchParams.get('code');
            const sessionState = searchParams.get('session_state');
            const access_token = localStorage.getItem('access_token')
            const login_type = localStorage.getItem('login_type')
            if (access_token) return
            async function fetchData() {
                try {
                    setLoading(true)
                    let codeChallenge = localStorage.getItem('codeChallenge');
                    codeChallenge = JSON.parse(codeChallenge)
                    let clientId = constants.CLIENT_ID
                    if (login_type === "dev") clientId = constants.CLIENT_ID_FOR_ZENSIS
                    else if (login_type === "internal") clientId = constants.CLIENT_ID_FOR_INTERNAL_ZENSIS
                    else clientId = constants.CLIENT_ID

                    const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
                    const loginRedirectUrl = `${baseURL}/auth/admin-login-redirect`

                    if (login_type === "internal") {
                        const token = await fetchTokenForInternal(clientId, loginRedirectUrl, code, sessionState, codeChallenge.code_verifier);
                        localStorage.setItem('access_token', token.data.accessToken)
                        localStorage.setItem('refresh_token', token.data.refreshToken)
                        // localStorage.setItem('id_token', token.id_token)
                    } else {
                        const token = await fetchToken(clientId, redirect_url, code, sessionState, codeChallenge.code_verifier);
                        // console.log("login-token", JSON.stringify(token))
                        // alert(JSON.stringify(token))
                        // localStorage.removeItem('codeChallenge')
                        localStorage.setItem('access_token', token.data.accessToken)
                        localStorage.setItem('refresh_token', token.data.refreshToken)
                        // localStorage.setItem('id_token', token.id_token)
                    }
                    // window.location.reload()
                    window.location.assign('/')
                } catch (error) {
                    console.log("error", error)

                    if (error.response && error.response.data && error.response.data.result &&
                        'ACCOUNT_SUSPENDED' === error.response.data.result.code) {
                        window.alert(error.response.data.result.errorMessage.en)

                        if (error.response.data.result.payload &&
                            error.response.data.result.payload.ssoLogoutURL) {

                            // https://gitlab.zensis.com/hkt-eshop/uat-comments/issues/234

                            // TODO: To Logout SSO, use code below
                            window.location.assign(error.response.data.result.payload.ssoLogoutURL);
                        }
                    }

                    let code = error.code
                    let errorMessage = error.message
                    if (error.response &&
                        error.response.data &&
                        error.response.data.result) {
                        if (error.response.data.result.errorMessage && error.response.data.result.errorMessage.en) {
                            errorMessage = error.response.data.result.errorMessage.en
                        }
                        if (error.response.data.result.code) {
                            code = error.response.data.result.code
                        }
                    }

                    navigate('/auth/admin-login-redirect');
                    setLoading(false)
                    setSnackbar({
                        type: 'error',
                        message: `${code}-${errorMessage} `
                    })
                }
            }
            fetchData()
        }
    }, [hasCode])

    // if (searchParams.has('error')) {
    //     setSnackbar({
    //         type: 'error',
    //         message: searchParams.get('error')
    //     })
    // }

    const onClickLogin = ({ isZensis }) => {
        let codeChallenge = localStorage.getItem('codeChallenge');
        codeChallenge = JSON.parse(codeChallenge)
        // const encodeUrl = encodeURIComponent(redirect_url)
        // const url = `https://hkt-eshop-admin-user-pool.auth.ap-southeast-1.amazoncognito.com/oauth2/authorize?client_id=${constants.CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${encodeUrl}&code_challenge_method=S256&code_challenge=${codeChallenge.code_challenge}`
        // const urlForZensis = `https://hkt-eshop-admin-user-pool.auth.ap-southeast-1.amazoncognito.com/oauth2/authorize?client_id=${constants.CLIENT_ID_FOR_ZENSIS}&response_type=code&scope=openid&redirect_uri=${encodeUrl}&code_challenge_method=S256&code_challenge=${codeChallenge.code_challenge}`
        // localStorage.setItem('login_type', isZensis ? 'dev' : 'uat')
        // window.location.assign(isZensis ? urlForZensis : url)
        localStorage.setItem('login_type', 'uat')

        let loginRedirectUrl = process.env.REACT_APP_LOGIN_CALLBACK_ENDPOINT
        if (!loginRedirectUrl) {
            const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
            loginRedirectUrl = `${baseURL}/auth/admin-login-redirect`
        }
        const encodeUrlForInternal = encodeURIComponent(loginRedirectUrl)
        
        const loginEndpoint = `${getCMSLoginUrl()}?response_type=code&scope=openid&redirect_uri=${encodeUrlForInternal}&code_challenge_method=S256&code_challenge=${codeChallenge.code_challenge}`
        window.location.assign(loginEndpoint)
    }

    const onClickInternalLogin = () => {
        let codeChallenge = localStorage.getItem('codeChallenge');
        codeChallenge = JSON.parse(codeChallenge)
        // const encodeUrlForInternal = encodeURIComponent(redirect_url_internal)
        // const urlForInternalZensis = `https://hkt-eshop-admin-user-pool-internal.auth.ap-southeast-1.amazoncognito.com/oauth2/authorize?client_id=${constants.CLIENT_ID_FOR_INTERNAL_ZENSIS}&response_type=code&scope=openid&redirect_uri=${encodeUrlForInternal}&code_challenge_method=S256&code_challenge=${codeChallenge.code_challenge}`
        localStorage.setItem('login_type', 'internal')
        // alert(`clientId: ${constants.CLIENT_ID_FOR_INTERNAL_ZENSIS}, ${redirect_url_internal}`)
        // window.location.assign(urlForInternalZensis)

        const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
        const loginRedirectUrl = `${baseURL}/auth/admin-login-redirect`
        // const loginRedirectUrl = process.env.REACT_APP_LOGIN_CALLBACK_ENDPOINT || `${window.location.origin}/auth/admin-login-redirect`
        const encodeUrlForInternal = encodeURIComponent(loginRedirectUrl)

        // Add "dev=1" for testing zensis login
        const loginEndpoint = `${getCMSLoginUrl()}?dev=1&response_type=code&scope=openid&redirect_uri=${encodeUrlForInternal}&code_challenge_method=S256&code_challenge=${codeChallenge.code_challenge}`
        window.location.assign(loginEndpoint)
    }

    if (loading) {
        return (
            (<Box display="flex" alignItems="center" justifyContent="center" height="100vh"><CircularProgress /></Box>)
        )
    }

    return (
        <Container>
            <Left>
                {constants.IS_DEV && 
                <div style={{ height: 30, width: 100, backgroundColor: 'red', color: '#fff', position: "absolute", top: 0, left: 0, display: 'flex', alignItems: 'center' }}>
                    ***IS DEV***
                </div>}
                <Typography>Login</Typography>
            </Left>
            <Right>
                <Content>
                    <Button style={{ width: 167, marginBottom: 20 }} onClick={onClickLogin}>Login</Button>
                    {/* <Button onClick={() => onClickLogin({isZensis: true})}>{`Login (Zensis development login)`}</Button> */}
                    {/*
                    // Only Available on ZENSIS DEV
                    <Button onClick={onClickInternalLogin} style={{ marginTop: 20, backgroundColor: "blue" }}>{`Login (Internal Zensis development login)`}</Button>
                    */}
                </Content>
                <Footer align="right">© Hong Kong Telecommunications (HKT) Limited 2023.</Footer>
            </Right>
            <Snackbar />
        </Container>
    )
}

export default Login

