import { Controller } from "react-hook-form";
import {
    TextField,
    Typography,
    Button,
    ComponentContainer,
    Select,
    UploadComponent
} from '../../components/share';

const DeliveryFields = ({ control, errors, readOnly }) => {
    return [
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Delivery Setting</Typography>
            )
        }, { id: "spacing" },
        {
            id: "delivery-day",
            component: (
                <Controller
                    name={`estimatedDeliveryDay`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Delivery Day (T+N Day)"
                            direction="vertical"
                            fullWidth
                            error={errors?.estimatedDeliveryDay}
                            helperText={errors?.estimatedDeliveryDay?.message}
                            readOnly={readOnly}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "pickup-day",
            component: (
                <Controller
                    name={`estimatePickupDay`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Pick Up day (T+N Day)"
                            direction="vertical"
                            fullWidth
                            error={errors?.estimatePickupDay}
                            helperText={errors?.estimatePickupDay?.message}
                            readOnly={readOnly}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Delivery Fee</Typography>
            )
        }, { id: "spacing" },
        {
            id: "minimum-free-shipping-amount",
            component: (
                <Controller
                    name={`freeShippingMinSpent`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Minimum Free Shipping Amount"
                            direction="vertical"
                            fullWidth
                            error={errors?.freeShippingMinSpent}
                            helperText={errors?.freeShippingMinSpent?.message}
                            readOnly={readOnly}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "delivery-fee-per-order",
            component: (
                <Controller
                    name={`deliveryFee`}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            title="Delivery Fee per order"
                            direction="vertical"
                            fullWidth
                            error={errors?.deliveryFee}
                            helperText={errors?.deliveryFee?.message}
                            readOnly={readOnly}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
    ]
}

const DocFields = ({ control, errors }) => {
    return [
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Document Upload</Typography>
            )
        }, { id: "spacing" },
        {
            id: "document-upload",
            component: (
                <Controller
                    name={`documentUpload`}
                    control={control}
                    render={({ field }) => {
                        if (!!field.value) {
                            return (
                                <Button variant="text" color="neon" >Preview</Button>
                            )
                        }
                        return (
                            <ComponentContainer direction="vertical" title="Mobile No. Portability (MNP) Download Form">
                                <UploadComponent
                                    renderComponent={() => (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                        >
                                            + Upload
                                        </Button>
                                    )}
                                />
                            </ComponentContainer>
                        )
                    }}
                />
            )
        }, { id: "spacing" },
        {
            id: "document-upload",
            component: (
                <Controller
                    name={`ownershipForm`}
                    control={control}
                    render={({ field }) => {
                        if (!!field.value) {
                            return (
                                <Button variant="underline">Preview</Button>
                            )
                        }
                        return (
                            <ComponentContainer direction="vertical" title="Change Ownership Form">
                                <UploadComponent
                                    renderComponent={({ selectFile }) => (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={selectFile}
                                        >
                                            + Upload
                                        </Button>
                                    )}
                                />
                            </ComponentContainer>
                        )
                    }}
                />
            )
        }, { id: "spacing" },
    ]
}

const TncFields = ({ control, errors, readOnly, tncList }) => {
    return [
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Postpaid</Typography>
            )
        }, { id: "spacing" },
        {
            id: "postpaid-tnc",
            component: (
                <Controller
                    name={`postPaid`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="Select T&C*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.postPaid}
                            helperText={errors?.postPaid?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "hr"
        },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Prepaid</Typography>
            )
        }, { id: "spacing" },
        {
            id: "prepaid-tnc",
            component: (
                <Controller
                    name={`prePaid`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="Select T&C*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.prePaid}
                            helperText={errors?.prePaid?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "hr"
        },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Privacy Statement</Typography>
            )
        }, { id: "spacing" },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Save Personal Info for next purchase</Typography>
            )
        }, { id: "spacing" },
        {
            id: "save-personal-info-for-next-purchase",
            component: (
                <Controller
                    name={`savePersonalInformation`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="Select T&C*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.savePersonalInformation}
                            helperText={errors?.savePersonalInformation?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Rejection for Direct Sale Promotion use</Typography>
            )
        }, { id: "spacing" },
        {
            id: "rejection-for-direct-sale-promotion-use",
            component: (
                <Controller
                    name={`directSaleThisServices`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="Select T&C (This Services)*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.promotion}
                            helperText={errors?.promotion?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "rejection-for-direct-sale-my-club-hkt",
            component: (
                <Controller
                    name={`directSaleMyClubHKT`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="Select T&C (The Club & MyHKT)*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.promotion}
                            helperText={errors?.promotion?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
        {
            id: "hr"
        },
        {
            id: 'header',
            component: (
                <Typography variant="header" sx={{ marginBottom: 1.5 }}>Statutory Free Removal Service</Typography>
            )
        }, { id: "spacing" },
        {
            id: "statutory-free-removal-service",
            component: (
                <Controller
                    name={`freeRemovalServices`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            title="Select T&C*"
                            direction="vertical"
                            fullWidth
                            textFieldProps={{
                                placeholder: "Select..."
                            }}
                            options={tncList}
                            value={field.value}
                            onChange={(value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(option) => option?.title?.en}
                            disabled={readOnly}
                            error={errors?.freeRemovalServices}
                            helperText={errors?.freeRemovalServices?.message}
                        />
                    )}
                />
            )
        }, { id: "spacing" },
    ]
}

export {
    DeliveryFields,
    DocFields,
    TncFields
}