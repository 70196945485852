import styled from 'styled-components';
import Card from './Card';
import Header from './PageHeader';
import { styled as styledMui } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router-dom';
import Button from './Button'

const Root = styledMui('div')(
    ({ theme }) => `
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10px;
        overflow: hidden;
        background-color: ${theme.palette.common.pageBgColor}
    `
);

const StyledCard = styled(Card)`
    && {
        height: 100%;
        overflow: hidden;
        position: relative;
    }
`


function PageWrapper({ renderHeader, showBackButton, renderBackButton, renderCardHeader, renderCustomCardHeader, children, loading }) {

    const navigate = useNavigate();

    return (
        <Root>
            <Header>
                {renderHeader && renderHeader()}
            </Header>
            {renderBackButton && renderBackButton()}
            {showBackButton ? <Button type='back' onClick={() => navigate(-1)} style={{ marginLeft: 10 }} /> : <Box height="34px" width="1px" />}
            <StyledCard>
                {
                    renderCardHeader &&
                    <Box display="flex" flexDirection="column" justifyContent="center" minHeight="65px" height="65px" maxHeight="65px">
                        <div style={{ height: '100%', width: 1 }} />
                        {renderCardHeader()}
                        <Divider sx={{ borderWidth: 2, borderColor: 'mainColor.light' }} />
                    </Box>
                }
                {
                    renderCustomCardHeader &&
                    <Box display="flex" flexDirection="column" justifyContent="center" minHeight="65px" height="65px" maxHeight="65px">
                        {renderCustomCardHeader()}
                    </Box>
                }
                {children}
                {loading &&
                    <Box sx={{ display: 'flex', position: 'absolute', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                        <CircularProgress />
                    </Box>}
            </StyledCard>
        </Root>
    )
}

export default PageWrapper