import dayjs, { Dayjs } from 'dayjs';
import { TextField, InputAdornment } from '@mui/material';
import MyTextField from './TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import Typography from './Typography';
import styled from 'styled-components';
import ComponentContainer from './ComponentContainer';
import 'dayjs/locale/en-gb';


const Container = styled.div`
    display: flex;
    flex-direction: column;
`

function DatePickerComponent({ title, fullWidth, type = "date", onChange, value, disabled, ...props}) {

    const Component = type === 'date' ? DatePicker : TimePicker

    if (disabled) {
        return (
            <MyTextField 
                title={title}
                direction='vertical'
                value={value ? dayjs(value).format('DD/MM/YYYY') : null}
                readOnly
                fullWidth
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <InsertInvitationIcon sx={{ color: "secondary.main" }} />
                      </InputAdornment>
                    ),
                  }}
            />
        )
    }
    
    return (
        <ComponentContainer direction='vertical' title={title} {...props}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
                <Component
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                variant="filled"
                                fullWidth={fullWidth}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    sx: {
                                        ...params.InputProps.sx,
                                        borderRadius: 1,
                                        backgroundColor: "secondary.light",
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#AEAEAE",
                                        },
                                    },
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    sx: {
                                        ...params.inputProps.sx,
                                        padding: `12px 14px`,
                                        color: "info.main",
                                        border: 'none',
                                  
                                    },
                                }}
                            />
                        )
                    }}
                    {...props}
                    value={value || null}
                    onChange={(newValue) => {
                        if (!newValue || !dayjs(newValue).isValid()) {
                            onChange && onChange(null)
                            return 
                        }
                        const newDate = dayjs(newValue)
                        const originDate = value ? dayjs(value) : dayjs().set('hour', 0).set('minute', 0).set('second', 0)
                        const tmp = originDate.set('date', newDate.get('date')).set('month', newDate.get('month')).set('year', newDate.get('year'))
                        onChange && onChange(tmp.toISOString())
                    }}
                />
            </LocalizationProvider>
        </ComponentContainer>

    )
}

export default DatePickerComponent;