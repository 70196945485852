import { request } from './index';
import constants from '../constants';

const updateOrderStatus = (id, payload) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/order/detail/${id}/status`,
            data: payload
        })
            .then(res => {
                // resolve(res.data)
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const fetchOrderDetail = (id) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "get",
            url: `${constants.API_ENDPOINT}/order/items/detail/${id}`
        })
            .then(res => {
                // resolve(res.data)
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const updateOrder = (id, payload) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "put",
            url: `${constants.API_ENDPOINT}/order/items/detail/${id}`,
            data: payload
        })
            .then(res => {
                // resolve(res.data)
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

const addOrderItemRemark = (id, payload) => {
    return new Promise((resolve, reject) => {
        return request({
            method: "post",
            url: `${constants.API_ENDPOINT}/order/items/detail/${id}/remarks`,
            data: payload
        })
            .then(res => {
                // resolve(res.data)
                resolve(res)
            })
            .catch((error) => {
                reject(error.result)
            })
    })
}

export {
    updateOrderStatus,
    fetchOrderDetail,
    updateOrder,
    addOrderItemRemark
}